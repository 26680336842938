import React from "react";
import Layout from "../../layout/interface/Layout";
import {useDocumentTitle} from "../../../components/Common";
import TradingInvestingPlans from "../../../components/TradingInvestingPlans";

export default function TradingInvesting() {

  useDocumentTitle('Trading & Investing');

  return (<Layout><TradingInvestingPlans/></Layout>);
}
