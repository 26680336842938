import { Navigate, Route } from "react-router";
import CommonPageRoutes from "./pages/common/CommonPageRoutes";
import Profile from "./pages/auth/interface/Profile";
import MyService from "./pages/auth/interface/MySubscriptions";
import LiveCalls from "./pages/calls/interface/LiveCalls";
import PreviousCalls from "./pages/calls/interface/PreviousCalls";
import Support from "./pages/support/interface/Support";
import RiskProfile from "./pages/risk-profile/interface/RiskProfile";

let isLoggedIn = sessionStorage.getItem("suat") || false;

const allRoutes = [
  ...CommonPageRoutes,
  {
    path: "/risk-profile",
    component: <RiskProfile />
  },
  {
    path: "/profile",
    component: <Profile />
  },
  {
    path: "/my-services",
    component: <MyService />
  },
  {
    path: "/live-calls",
    component: <LiveCalls />
  },
  {
    path: "/previous-calls",
    component: <PreviousCalls />
  },
  {
    path: "/support",
    component: <Support />
  }
];

export default allRoutes.map((row, i) => {
  return (<Route path={row.path} key={i}
    element={isLoggedIn ? row.component : <Navigate to="/get-started" />} >
  </Route>);
});

