import {ChevronRightIcon, LinkIcon, MoveRight} from "lucide-react";
import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {
  cancelSignDocument,
  digioSignedUpdatePayment,
  getKYCDetails,
  paymentRequest,
  requestSignDocument,
  updateRazorpaySuccess
} from "../pages/service/trading-investing/TradingInvesting.service";
import {DIGIO_ENV, RAZORPAY_KEY, V1_API_ORIGIN} from "../config/constants.config";
import axios from "axios";
import states from "../pages/service/trading-investing/state.json";
import OffCanvasRight from "../pages/layout/interface/off-canvas-right";
import * as Yup from "yup";
import {useNavigate} from "react-router-dom";
import {handleScrollToTop} from "./Common";

export const MakePaymentProcess = ({planData, componentKey, total, gst, grandTotal}) => {
  const authState = useSelector((state) => state.auth.authState);
  const userDetail = useSelector((state) => state.auth.userDetail);
  const rpfId = useSelector((state) => state.auth.userDetail.rpfId);
  const [hasUserDataBeforePayment, setHasUserDataBeforePayment] = useState({
    cusCountry: "INDIA", cusState: "", cusCity: "",
  });
  const [digioSignUpdate, setDigioSignUpdate] = useState({
    status: '', details: {}
  });
  const [isKycFilled, setIsKycFilled] = useState(false);
  const [hasErrorMessage, setHasErrorMessage] = useState(false);
  const [requestSignData, setRequestSignData] = useState({});
  const [hasKycData, setHasKycData] = useState({});
  const buttonRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    if (typeof requestSignData.id !== 'undefined') {
      window.paymentID = requestSignData.paymentId;
      const digio = new Digio({
        environment: DIGIO_ENV,
        callback: async (response) => {
          if (response.hasOwnProperty("error_code") && response.error_code === "CANCELLED") {
            setDigioSignUpdate((prevState) => ({
              ...prevState,
              status: 'CANCELLED',
              details: {...response, paymentID: window.paymentID}
            }));
          } else {
            setDigioSignUpdate((prevState) => ({
              ...prevState,
              status: 'SUCCESS',
              details: {...response, paymentID: window.paymentID}
            }));
          }
        },
        theme: {
          darkColor: "#AB3498", secondaryColor: "#000000",
        },
        Is_redirection_approach: false,
        is_iframe: true,
      });
      digio.init();
      digio.submit(requestSignData.id, requestSignData.signing_parties[0]?.identifier, requestSignData.access_token?.id);
    }
  }, [requestSignData]);

  useEffect(() => {
    const finalDiscountPrice = planData === null ? 0 : Object.values(planData)[0]?.discountPrice;
    const finalGST = finalDiscountPrice * 0.18;
    window.finalPayableAmount = Math.ceil(finalDiscountPrice + finalGST);

    if (digioSignUpdate.status === 'SUCCESS') {
      digioSignedUpdatePayment({
        paymentId: digioSignUpdate.details.paymentID,
        digioDesc: "Signed",
        digioDocId: digioSignUpdate.details.digio_doc_id,
        digioDocSignStatus: "success",
      }).then(() => {
        navigate(`/make-payment?amount=${window.finalPayableAmount}`);
        handleScrollToTop();
      });

      // paymentRequest()
      //   .then((response) => {
      //     const options = {
      //       key: RAZORPAY_KEY,
      //       amount: response.data.data.amount.toString(),
      //       currency: "INR",
      //       name: "Equitypandit Financial Services Private Limited",
      //       description: "Packages",
      //       image: `/ep-imgs/EPfavicon.png`,
      //       order_id: response.data.data.id,
      //       handler: function (response) {
      //         updateRazorpaySuccess({
      //           payStatus: "success",
      //           razorpayOrderId: response.razorpay_order_id,
      //           razorpayPaymentId: response.razorpay_payment_id,
      //         }).then(() => {
      //           getKYCDetails()
      //             .then((response) => {
      //               setHasKycData(response.data.data);
      //               if (response.data.data?.id !== undefined) {
      //                 // navigate("/my-services");
      //               }
      //             })
      //             .catch(() => {
      //               // buttonRefPopInitiator.current.click();
      //             });
      //         });
      //       },
      //       prefill: {
      //         name: userDetail.firstName, email: userDetail.email, contact: userDetail.mobile,
      //       },
      //       theme: {
      //         color: "#008254",
      //       },
      //     };
      //
      //     const paymentObject = new window.Razorpay(options);
      //     paymentObject.open();
      //   });

    } else if (digioSignUpdate.status === 'CANCELLED') {
      cancelSignDocument(digioSignUpdate.details.digio_doc_id);
    }
  }, [digioSignUpdate.status]);

  useEffect(() => {

  }, [planData]);

  const submit = (values) => {
    setHasUserDataBeforePayment(values);
    const valueClone = {...values, total, gst, grandTotal};
    return requestSignDocument(rpfId, planData, valueClone)
      .then((response) => {
        buttonRef.current?.click();
        setRequestSignData(response.data.data);
      })
      .catch((e) => {
        setHasErrorMessage(e.response?.data?.message);
      });
  }

  const validationSchema = Yup.object({
    cusCountry: Yup.string()
      .required("Country is required")
      .max(100, "Country should be less then 100 characters."), cusState: Yup.string()
      .required("State is required")
      .max(100, "State should be less then 100 characters."), cusCity: Yup.string()
      .required("City is required")
      .max(100, "City should be less then 100 characters."),
  });

  const validationSchemaKyc = Yup.object({
    name: Yup.string()
      .required("Name is required")
      .max(255, "Name should be less then 100 characters."), mobile: Yup.string()
      .required("Mobile is required")
      .max(255, "Mobile should be less then 100 characters."), email: Yup.string()
      .required("Email is required")
      .max(255, "Email should be less then 100 characters."), city: Yup.string()
      .required("City is required")
      .max(255, "City should be less then 100 characters."), country: Yup.string()
      .required("Country is required")
      .max(255, "Country should be less then 100 characters."), pan: Yup.string()
      .required("PAN is required")
      .max(255, "PAN should be less then 100 characters."), address: Yup.string()
      .required("Address is required")
      .max(255, "Address should be less then 100 characters."),
  });

  return (<>
    {authState ? <button
      disabled={componentKey !== parseInt(Object.keys(planData)[0])}
      type="button"
      data-bs-toggle="modal"
      data-bs-target="#service_process"
      className="btn btn-success w-100"
    >
      Continue <MoveRight size={16}/>
    </button> : <button
      className="btn btn-success w-100"
      data-bs-toggle="offcanvas"
      data-bs-target="#offcanvasRight"
      style={{cursor: "pointer"}}
    >
      Continue <MoveRight size={16}/>
    </button>}

    <div className="modal fade" id="service_process" tabIndex="-1" aria-labelledby="service_processLabel"
         aria-hidden="true" data-bs-backdrop="static">
      <div className="modal-dialog modal-md modal-confirm modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content table">
          <div className="modal-header">
            <h5 className="modal-title fw-bold">
              {!isKycFilled ? "Proceed with agreement" : "Complete KYC"}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              ref={buttonRef}>
            </button>
          </div>
          <div className="modal-body pt-1 mb-2">
            <div className="row">
              {!isKycFilled ? <Formik
                enableReinitialize
                initialValues={{cusCountry: "INDIA", cusState: "", cusCity: "",}}
                validationSchema={validationSchema}
                onSubmit={(values, {setSubmitting, resetForm}) => {
                  submit(values)
                    .then(() => {
                      setSubmitting(false);
                      resetForm();
                    });
                }}>
                {({isSubmitting, touched, errors, values}) => (<Form>
                  <div className="row">
                    <div className="col-6">
                      <small className="d-block">Name</small>
                      <small>{userDetail.firstName}</small>
                    </div>
                    <div className="col-6">
                      <small className="d-block">Mobile</small>
                      <small>{userDetail.mobile}</small>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 col-6">
                      <small style={{fontSize: "13px"}}>
                        Country
                      </small>
                      <Field
                        as="select"
                        name="cusCountry"
                        className={`form-control form-control-sm mt-1 ${touched.cusCountry && errors.cusCountry && "is-invalid"}`}
                        value={values.cusCountry}
                      >
                        <option value="">-- Select --</option>
                        <option value="INDIA">INDIA</option>
                        {/*<option value="">Other</option>*/}
                      </Field>
                      <ErrorMessage
                        name="cusCountry"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>

                    {values.cusCountry === "INDIA" ? (<div className="col-md-4 col-6">
                      <small style={{fontSize: "13px"}}>
                        State
                      </small>
                      <Field
                        as="select"
                        name="cusState"
                        className={`form-control form-control-sm mt-1 ${touched.cusState && errors.cusState && "is-invalid"}`}
                        // required={true}
                        value={values.cusState}
                      >
                        <option value="">-- Select --</option>
                        {states.map((item, index) => (<option value={item.value} key={index}>
                          {item.text}
                        </option>))}
                      </Field>
                      <ErrorMessage
                        name="cusState"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>) : (<div className="col-md-4 col-6">
                      <small style={{fontSize: "13px"}}>
                        Country
                      </small>
                      <Field
                        className={`form-control form-control-sm mt-1 ${touched.cusState && errors.cusState && "is-invalid"}`}
                        value={values.cusState}
                        name="cusState"
                        // required={true}
                      />
                      <ErrorMessage
                        name="cusState"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>)}

                    <div className="col-md-4 col-6">
                      <small style={{fontSize: "13px"}}>City</small>
                      <Field
                        className={`form-control form-control-sm mt-1 ${touched.cusCity && errors.cusCity && "is-invalid"}`}
                        value={values.cusCity}
                        name="cusCity"
                        // required={true}
                      />
                      <ErrorMessage
                        name="cusCity"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                  {!(userDetail.rpfProfileType && authState) && (
                    <div className="text-center text-danger">
                      <span>
                        <b>{hasErrorMessage}</b>
                      </span>
                      <br/>
                      <a href="/risk-profile">
                        Please complete your risk profile first.{" "}
                        <LinkIcon size={15}/>
                      </a>
                    </div>)
                  }
                  <div className="col-12 text-end">
                    <div className="my-2">
                      <button
                        type="submit"
                        className="btn btn-success px-3 w-100"
                        disabled={isSubmitting || (!userDetail.rpfProfileType && authState)}
                      >
                        {isSubmitting ? "Loading..." : "Proceed"}
                        {!isSubmitting && (<ChevronRightIcon size={20}/>)}
                        <i className="bi bi-arrow-right"></i>
                      </button>
                    </div>
                    <p></p>
                  </div>
                </Form>)}
              </Formik> : <Formik
                initialValues={{
                  userId: userDetail.id,
                  ckycNo: "-",
                  name: userDetail.firstName,
                  email: userDetail.email,
                  mobile: userDetail.mobile,
                  address: "",
                  city: hasUserDataBeforePayment.cusCity,
                  state: hasUserDataBeforePayment.cusState,
                  country: hasUserDataBeforePayment.cusCountry,
                  pan: "",
                  isCompleted: true,
                }}
                enableReinitialize
                validationSchema={validationSchemaKyc}
                onSubmit={async (values, {setSubmitting}) => {
                  await axios.post(`${V1_API_ORIGIN}/kyc`, values);
                  await buttonRef.current.click();
                  navigate("/my-services");
                  setHasUserDataBeforePayment({cusCountry: "INDIA", cusState: "", cusCity: ""})
                }}>
                {({isSubmitting, touched, errors, values}) => (<Form>
                  <div className="row">
                    {hasKycData?.id && (<div className="col-12 mb-3 text-center bg-secondary-subtle py-2">
                      Your KYC details are up to date.
                    </div>)}
                    <div className="col-6 mb-2">
                      <small className="d-block">Name</small>
                      <Field
                        name="name"
                        className={`form-control form-control-sm mt-1 ${touched.name && errors.name && "is-invalid"}`}
                        value={values.name}
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="col-6 mb-2">
                      <small className="d-block">Mobile</small>
                      <Field
                        name="mobile"
                        className={`form-control form-control-sm mt-1 ${touched.mobile && errors.mobile && "is-invalid"}`}
                        value={values.mobile}
                      />
                      <ErrorMessage
                        name="mobile"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="col-6 mb-2">
                      <small className="d-block">Email</small>
                      <Field
                        name="email"
                        className={`form-control form-control-sm mt-1 ${touched.email && errors.email && "is-invalid"}`}
                        value={values.email}
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="col-6 mb-2">
                      <small className="d-block">Country</small>
                      <Field
                        as="select"
                        name="country"
                        className={`form-control form-control-sm mt-1 ${touched.country && errors.country && "is-invalid"}`}
                        value={values.country}
                      >
                        <option value="">-- Select --</option>
                        <option value="INDIA">INDIA</option>
                        <option value="">OTHER</option>
                      </Field>
                    </div>
                    {["INDIA"].includes(values.country) ? (<div className="col-6 mb-2">
                      <small className="d-block">State</small>
                      <Field
                        as="select"
                        name="state"
                        className={`form-control form-control-sm mt-1 ${touched.state && errors.state && "is-invalid"}`}
                        value={values.state}
                      >
                        <option value="">-- Select --</option>
                        {states.map((item, index) => (<option value={item.value} key={index}>
                          {item.text}
                        </option>))}
                      </Field>
                      <ErrorMessage
                        name="state"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>) : (<div className="col-6 mb-2">
                      <small className="d-block">Country</small>
                      <Field
                        name="country"
                        className={`form-control form-control-sm mt-1 ${touched.country && errors.country && "is-invalid"}`}
                        value={values.country}
                      />
                      <ErrorMessage
                        name="country"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>)}

                    <div className="col-6 mb-2">
                      <small className="d-block">City</small>
                      <Field
                        name="city"
                        className={`form-control form-control-sm mt-1 ${touched.city && errors.city && "is-invalid"}`}
                        value={values.city}
                      />
                      <ErrorMessage
                        name="city"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 col-6">
                      <small style={{fontSize: "13px"}}>PAN</small>
                      <Field
                        name="pan"
                        className={`form-control form-control-sm mt-1 ${touched.pan && errors.pan && "is-invalid"}`}
                        value={values.pan || hasKycData.pan}
                      ></Field>
                      <ErrorMessage
                        name="pan"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>

                    <div className="col-md-12 col-12">
                      <small style={{fontSize: "13px"}}>
                        Full Address (as per document)
                      </small>
                      <Field
                        name="address"
                        className={`form-control form-control-sm mt-1 ${touched.address && errors.address && "is-invalid"}`}
                        value={values.address || hasKycData.address}
                      ></Field>
                      <ErrorMessage
                        name="address"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                  {!(userDetail.rpfProfileType && authState) && (<div className="text-center text-danger">
                    <span>
                      <b>{hasErrorMessage}</b>
                    </span>
                    <br/>
                    <a href="/risk-profile">
                      Please complete your risk profile first.{" "}
                      <LinkIcon size={15}/>
                    </a>
                  </div>)}
                  <div className="col-12 text-end">
                    <div className="my-2">
                      <button
                        type="submit"
                        className="btn btn-success px-3 w-100"
                        disabled={isSubmitting || (!userDetail.rpfProfileType && authState)}
                      >
                        {isSubmitting ? "Loading..." : "Proceed"}
                        {!isSubmitting && (<ChevronRightIcon size={20}/>)}
                        <i className="bi bi-arrow-right"></i>
                      </button>
                    </div>
                    <p></p>
                  </div>
                </Form>)}
              </Formik>}
            </div>
          </div>
        </div>
      </div>
    </div>

    <OffCanvasRight section="mobile"/>
  </>);
}
