import React from 'react';
import Layout from "../layout/interface/Layout";
import {useDocumentTitle} from "../../components/Common";
import {useParams} from "react-router-dom";

const PrivacyPolicy = () => {
  let {device} = useParams()

  const UI = (
    <>
      <section className="my-3">
        <div className="container py-lg-5 pt-5 pb-0">
          <div className="fs-2 text-black fw-bold mx-3 px-3 mx-lg-5 px-lg-4 mx-md-3 px-md-3">Privacy Policy</div>
          <div className="mx-3 px-3 mx-lg-5 px-lg-4 mx-md-3 px-md-3">
            <h4 className="mt-3 mt-lg-5 mt-md-3" style={{color: "#000"}}>Disclaimer:</h4>
            <p className="mt-3">EquityPandit Financial Services Private Limited respects and values the
              right to Privacy of each and every individual.
              We are esteemed by the relationship and by becoming our clients you have a promise from our side
              that we shall remain loyal to all our clients and non clients whose information resides with us.
              This Privacy Policy of EquityPandit applies to the current clients as well as former clients.
              Below are the word by word credentials of our Privacy Policy:
            </p>
            <ol>
              <li>Your information whether public or private will not be sold, rented, exchanged, transferred
                or given to any company or individual for any reason without your consent for the same.
              </li>
              <li>The only use we will be bringing to your information will be for providing the services to
                you for which you have subscribed to us for which you gave us your information.
              </li>
              <li>Your information given to us represents your identity with us. If any changes are brought in
                any of the fields of which you have provided us the information, you shall bring it to our
                notice by either calling us or dropping a mail to us.
              </li>
              <li>In addition to the service provided to you your information (Mobile Number, E-Mail ID etc.)
                can be brought in use for sending you newsletters, survey, contest information, or
                information about any new services of the Company which will be for your own good and while
                subscribing for our services you agree that EquityPandit has the right to do so.
              </li>
              <li>By subscribing to our services you consent to our Privacy Policy and Terms of Use.</li>
            </ol>
            <h4 className="mt-5" style={{color: "#000"}}>Governing Laws :</h4>
            <p>The terms of this agreement are exclusively based on & subject to Indian Law. You hereby consent
              to the exclusive jurisdiction and venue of courts in Jaipur, India in all disputes arising out
              of or relating to the use of this website. Use of this website is unauthorized in any
              jurisdiction that does not give effect to all provisions of these terms & conditions, including
              without limitation this paragraph.</p>
          </div>
        </div>
      </section>
    </>
  )

  useDocumentTitle('Privacy Policy');
  if (device === "mobile") {
    return UI;
  } else {
    return (
      <Layout>
        {UI}
      </Layout>
    );
  }
};

export default PrivacyPolicy;
