import {useEffect, useState} from "react";
import moment from "moment";
import {servicesService, previousCallsService} from "../service/previous-calls.service";
import Layout from "../../layout/interface/Layout";

export default function PreviousCalls() {
  const [hasServices, setHasServices] = useState([]);
  const [selectedService, setSelectedService] = useState("");
  const [hasPreviousCalls, setHasPreviousCalls] = useState([]);

  const fetchServices = () => {
    return servicesService()
      .then((response) => {
        setHasServices(response.data.data);
      })
  }

  useEffect(() => {
    previousCallsService(selectedService)
      .then((response) => {
        setHasPreviousCalls(response.data.data?.dataList || []);
      });
  }, [selectedService]);

  useEffect(() => {
    fetchServices();
  }, []);

  return (
    <Layout>
      <section className="bg-success bg-opacity-25">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="card p-3 mt-lg-5">
                <h6 className="mb-0 fs-24 fw-bold mb-3">Previous Calls</h6>
                <div className="row">
                  <div className="col-lg-4 mb-2">
                    <select className="form-select"
                            onChange={(e) => setSelectedService(e.target.value)}>
                      <option value="">Select a package</option>
                      {
                        (hasServices || []).map(item => (
                          <option key={item.pacId} value={item.pacId}>{item.pacName}</option>
                        ))
                      }
                    </select>
                  </div>
                  <div className="col-lg-12">
                    {
                      selectedService ?

                        <>
                          {(hasPreviousCalls || []).map((item, index) => (
                            <div key={index} className={`bg-secondary bg-opacity-10 mb-2 d-flex flex-column rounded-3 py-3 px-2 border-3 border-start w-100
                                                ${item.callType === "MAIN"
                              ? "border-success"
                              : "border-secondary"}`}>
                              {item.callType === "MAIN"
                                ?
                                <div>
                                  <div className="d-flex mb-2">
                                    <small className="badge text-success bg-opacity-50">
                                      New Call
                                    </small>
                                    <small className="badge bg-opacity-25"
                                           style={{backgroundColor: "#f55505"}}>{item.pacInvestmentType}</small>
                                    <small className="ms-auto badge text-secondary fw-normal">
                                      {moment(item.epDateTime).format("DD MMM YYYY, HH:MM:SS A")}
                                    </small>
                                  </div>
                                  <small>{item.serName}: {item.serMsg}</small>
                                </div>
                                : <div>
                                  <div className="d-flex mb-2">
                                    <small className="badge text-secondary bg-opacity-50">
                                      Follow Up
                                    </small>
                                    <small className="badge bg-opacity-25"
                                           style={{backgroundColor: "#f55505"}}>{item.pacInvestmentType}</small>
                                    <small
                                      className="ms-auto badge text-secondary fw-normal">
                                      {moment(item.epDateTime).format("YYYY-MM-DD HH:MM:SS A")}
                                    </small>
                                  </div>
                                  <small>{item.serName}: {item.serMsg}</small>
                                  <div className="bg-primary-subtle p-1 rounded px-3 opacity-75"
                                       style={{fontSize: "14px"}}>
                                    <small>{item.serName}: {item.mainSerMsg}</small>
                                  </div>
                                </div>}
                            </div>
                          ))}
                          {hasPreviousCalls.length <= 0 &&
                            <div className="mt-4 text-center bg-info rounded py-2 bg-opacity-75">No
                              calls
                              Yet</div>}
                        </>
                        : <div className="mt-4 text-center bg-info rounded py-2 bg-opacity-75">
                          Select a Package to view calls
                        </div>
                    }
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}
