"use client";
import React from "react";
import { useDocumentTitle } from "../../components/Common";
import Layout from "../layout/interface/Layout";
import {
  ChartAreaIcon,
  GitGraphIcon,
  MailIcon,
  MailOpenIcon,
  MoveRightIcon,
  PhoneCallIcon,
} from "lucide-react";

const Careers = () => {
  useDocumentTitle("Careers");
  return (
    <Layout>
      <section className="bg-green py-5">
        <div className="container py-5">
          <div className="row g-5 align-items-center">
            <div className="col-md-6 text-center text-md-start">
              <h1 className="fw-bold mb-3">
                Empower Your Journey
                <br /> Build, Innovate, Transform
              </h1>
              <button className="btn btn-dark">
                View Current Openings <MoveRightIcon size={22} />
              </button>
            </div>
            <div className="col-md-6">
              <div className="row g-3 align-items-center">
                <div className="col-md-5 col-6">
                  <div className="rounded-3 p-3 pt-5  bg-white text-end shadow-sm">
                    <h3 className="mb-0 fw-bold">1M+</h3>
                    <small>Users</small>
                  </div>
                </div>
                <div className="col-md-5 col-6">
                  <div className="rounded-3 p-3 pt-5  bg-success text-white text-end shadow-sm">
                    <h3 className="mb-0 fw-bold">19+</h3>
                    <small>Years of experience</small>
                  </div>
                </div>
                <div className="col-md-5 col-6">
                  <div className="rounded-3 p-3 pt-5  bg-success text-white text-end shadow-sm">
                    <h3 className="mb-0 fw-bold">1M+</h3>
                    <small>Users</small>
                  </div>
                </div>
                <div className="col-md-5 col-6">
                  <div className="rounded-3 p-3 pt-5  bg-white text-end shadow-sm">
                    <h3 className="mb-0 fw-bold">19+</h3>
                    <small>Years of experience</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="my-5">
        <div className="container py-5">
          <div className="row g-3">
            <div className="col-md-12">
              <h1 className="fw-bold mb-3">
                Work + life — helping you strike a balance
              </h1>
            </div>
            <div className="col-md-4">
              <div className="p-3 bg-success bg-opacity-10 rounded-3">
                <ChartAreaIcon size={50} />
                <h4 className="my-3">5 Days Working</h4>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="p-3 bg-success bg-opacity-10 rounded-3">
                <ChartAreaIcon size={50} />
                <h4 className="my-3">Festival Celebration</h4>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="p-3 bg-success bg-opacity-10 rounded-3">
                <ChartAreaIcon size={50} />
                <h4 className="my-3">Training & Workshops</h4>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="p-3 bg-success bg-opacity-10 rounded-3">
                <ChartAreaIcon size={50} />
                <h4 className="my-3">Growing Environment</h4>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="p-3 bg-success bg-opacity-10 rounded-3">
                <ChartAreaIcon size={50} />
                <h4 className="my-3">Leave Encashment</h4>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="p-3 bg-success bg-opacity-10 rounded-3">
                <ChartAreaIcon size={50} />
                <h4 className="my-3">Referral Bonus</h4>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-success bg-opacity-10 py-5">
        <div className="container my-5">
          <div className="row g-3">
            <div className="col-md-12">
              <h1 className="fw-bold">Our Openings</h1>
            </div>
            <div className="col-12">
              <div className="p-3 bg-white rounded-3">
                <small>Information & Technology</small>
                <h4>Sr. Flutter Developer</h4>

                <div className="row g-3 mt-4">
                  <div className="col-md-3">
                    <small className="text-muted">Location</small>
                    <p>Surat, Gujarat, India</p>
                    <small className="text-muted">Department</small>
                    <p>Information & Technology</p>
                    <small className="text-muted">Vacancies</small>
                    <p>2 Positions</p>
                    <small className="text-muted">Working Days</small>
                    <p>Monday to Friday</p>
                    <small className="text-muted">Timings</small>
                    <p>09:15 AM - 06:30 PM</p>
                    <button className="btn btn-success">
                      Apply Now <MoveRightIcon size={22} />
                    </button>
                  </div>
                  <div className="col-md-auto d-none d-md-block">
                    <div class="vr h-100"></div>
                  </div>
                  <div className="col-md-8">
                    <h5>About Equitypandit</h5>
                    <p>
                      EquityPandit is a leading research and advisory firm in
                      India, The firm is one of the biggest player with a
                      dominant position in both Institutional and Retail.
                      Company specializes in the business of Analysis,
                      Information and Intelligence. We have made an effort at to
                      bring you the top technical, fundamental basics of stock.
                    </p>
                    <h5>About the role</h5>
                    <p>
                      As a Senior Flutter Developer, you will be responsible for
                      designing and building advanced mobile applications for
                      Android and iOS platforms using Flutter. You’ll work
                      closely with cross-functional teams to define, design, and
                      ship new features. In this role, you will mentor junior
                      developers, review code, and ensure the app’s performance,
                      responsiveness, and scalability. You’ll also play a key
                      part in maintaining high standards of quality through
                      rigorous testing and code reviews.
                    </p>
                    <h5>Waht you'll do</h5>
                    <ul>
                      <li>
                        Develop high-quality, responsive, and scalable mobile
                        applications using Flutter.
                      </li>
                      <li>
                        Collaborate with product managers, designers, and
                        backend engineers to build new features and improve
                        existing functionality.
                      </li>
                      <li>
                        Write clean, maintainable, and well-documented code.
                      </li>
                      <li>
                        Conduct unit testing, integration testing, and debugging
                        to ensure app reliability.
                      </li>
                      <li>
                        Provide guidance and mentorship to junior developers,
                        participate in code reviews, and ensure adherence to
                        best practices.
                      </li>
                      <li>
                        Optimize app performance and troubleshoot complex issues
                        across different devices.
                      </li>
                    </ul>
                    <h5>Waht we are looking for</h5>
                    <ul>
                      <li>
                        3-6 years of experience in mobile application
                        development with at least 2 years in Flutter.
                      </li>
                      <li>
                        Strong knowledge of Dart, along with native mobile
                        development (Android/iOS) experience.
                      </li>
                      <li>
                        Experience with RESTful APIs, third-party libraries, and
                        integrating various SDKs.
                      </li>
                      <li>
                        Familiarity with modern state management techniques like
                        Provider, Bloc, or Riverpod.
                      </li>
                      <li>
                        Strong problem-solving skills and attention to detail,
                        with a passion for delivering high-quality products.
                      </li>
                      <li>
                        Excellent teamwork and communication skills, with a
                        proactive attitude to take ownership of tasks.
                      </li>
                    </ul>
                    <br />
                    <small className="text-muted">
                      <PhoneCallIcon size={16} /> Contact Us
                    </small>
                    <p>+91 8000 816 688</p>
                    <small className="text-muted">
                      <MailIcon size={16} /> Email Us
                    </small>
                    <p>careers@equitypandit.com</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Careers;
