import { useDispatch, useSelector } from "react-redux";
import { clearSession, reduxLogout } from "../../../redux/auth/auth";
import { Link, useNavigate, useNavigation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  MySubscriptionService,
  RequestService,
} from "../../auth/service/MySubscription.service";
import { APP_URL } from "../../../config/constants.config";
import {
  GiftIcon,
  HeartHandshakeIcon,
  ListIcon,
  LogOutIcon,
  PhoneCallIcon,
  PhoneIcon,
  UserIcon,
  Menu,
  MoveRight,
  DownloadIcon,
  Package2Icon,
  HomeIcon,
} from "lucide-react";
import OffCanvasRight from "./off-canvas-right";
import { handleScrollToTop } from "../../../components/Common";

const Header = () => {
  const [MySubscriptionData, setMySubscriptionData] = useState(true);
  const [userRatingNegative, setUserRatingNegative] = useState(false);
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");
  const current = useSelector((state) => state.current);
  const userDetail = useSelector((state) => state.auth.userDetail);
  const authState = useSelector((state) => state.auth.authState);
  const dispatch = useDispatch();
  const router = useNavigate();
  const suat =
    typeof window !== "undefined" ? sessionStorage.getItem("suat") : false;
  const mobile =
    typeof window !== "undefined" ? sessionStorage.getItem("mobile") : false;

  const toggleTheme = () => {
    const instance = document.getElementsByTagName("HTML")[0];
    const currentTheme = instance.getAttribute("data-bs-theme");
    const newTheme = currentTheme === "light" ? "dark" : "light";
    instance.setAttribute("data-bs-theme", newTheme);
    localStorage.setItem("theme", newTheme);
    setTheme(newTheme);
  };

  const setInitialTheme = () => {
    const instance = document.getElementsByTagName("HTML")[0];
    instance.setAttribute("data-bs-theme", theme);
  };

  const fetchData = () => {
    try {
      // MySubscriptionService().then((response) => {
      //   setMySubscriptionData(response.data.isSubscribed);
      // });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    setInitialTheme();
  }, []);

  const logout = () => {
    localStorage.removeItem("persist:root");
    // navigate('/login');
    dispatch(signOut());
    logOutService();
    window.location.href = `${APP_URL}/sign-in`;
  };

  const customStyle = {
    position: "absolute",
    inset: "0px 0px auto auto",
    margin: "0px",
    transform: "translate3d(0px, 35px, 0px)",
    width: "90% !important",
  };

  function handleRedirect(link) {
    router(link);
    handleScrollToTop();
  }

  return (
    <>
      <header className="sticky-top bg-white shadow-sm">
        <div className="container">
          <div className="row align-items-center justify-content-between py-2">
            <div className="col-md-3 col-7 order-md-1 order-1">
              <div className="d-flex align-items-center">
                <button
                  className="btn btn-sm btn-outline-secondary d-block d-md-none border-0 mb-1"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarScroll"
                  aria-controls="navbarScroll"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <Menu size={22} />
                </button>
                <div
                  className="navbar-brand uni-cursor-pointer"
                  onClick={() => handleRedirect("/")}
                  to="/"
                >
                  <img
                    className="mb-1 img-fluid"
                    src="/ep-imgs/ep-advisor-logo.svg"
                    alt="EP-Investment-Advisor"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12 order-md-2 order-3 text-start">
              <nav className="navbar navbar-expand-lg py-0">
                <div className="container">
                  <div className="collapse navbar-collapse" id="navbarScroll">
                    <ul className="navbar-nav ms-auto my-2 my-lg-0 navbar-nav-scroll ">
                      <li className="nav-item mx-1">
                        <div
                          className="nav-link active uni-cursor-pointer"
                          aria-current="page"
                          onClick={() => handleRedirect("/")}
                        >
                          <small>
                            <i className="fa fa-home"></i> Home
                          </small>
                        </div>
                      </li>

                      <li className="nav-item mx-1 dropdown d-none">
                        <a
                          className="nav-link dropdown-toggle"
                          href="#"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <small>The Company</small>
                        </a>
                        <ul className="dropdown-menu">
                          <li>
                            <div
                              className="dropdown-item uni-cursor-pointer"
                              onClick={() => handleRedirect("/the-company")}
                            >
                              <small>About Us</small>
                            </div>
                          </li>
                          <li>
                            <div
                              className="dropdown-item uni-cursor-pointer"
                              onClick={() => handleRedirect("/careers")}
                            >
                              <small>Careers</small>
                            </div>
                          </li>
                          <li>
                            <div
                              className="dropdown-item uni-cursor-pointer"
                              onClick={() => handleRedirect("/contact-us")}
                            >
                              <small>Contact Us</small>
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item mx-1 dropdown">
                        <a
                          className="nav-link dropdown-toggle text-body-emphasis"
                          href=""
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <small>
                            <i className="fa fa-briefcase"></i> Services
                          </small>
                        </a>
                        <ul className="dropdown-menu">
                          <li className="border-bottom pb-2">
                            <a
                              className="dropdown-item uni-cursor-pointer"
                              onClick={() =>
                                handleRedirect("/services/trading-investing")
                              }
                            >
                              <small className="d-block text-black">
                                Investing & Trading
                              </small>
                              <small
                                className="text-muted"
                                style={{ fontSize: "12px" }}
                              >
                                Sub text for Investing & Trading
                              </small>
                            </a>
                          </li>

                          <li className="border-bottom pb-2">
                            <a
                              className="dropdown-item uni-cursor-pointer"
                              onClick={() =>
                                handleRedirect("/services/hni-uhni")
                              }
                            >
                              <small className="d-block text-black">
                                HNI & UHNI Services
                              </small>
                              <small
                                className="text-muted"
                                style={{ fontSize: "12px" }}
                              >
                                Sub text for HNI & UHNI Services
                              </small>
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item uni-cursor-pointer"
                              onClick={() =>
                                handleRedirect(
                                  "/services/institutional-investors"
                                )
                              }
                            >
                              <small className="d-block text-black">
                                Institutional Investors
                              </small>
                              <small
                                className="text-muted"
                                style={{ fontSize: "12px" }}
                              >
                                Sub text for Institutional Investors
                              </small>
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item mx-1">
                        <div
                          className="nav-link active uni-cursor-pointer"
                          aria-current="page"
                          onClick={() => handleRedirect("/contact-us")}
                        >
                          <small>
                            <i className="fa fa-phone"></i> Contact Us
                          </small>
                        </div>
                      </li>
                      <li className="nav-item mx-1 dropdown d-none">
                        <a
                          className="nav-link dropdown-toggle text-body-emphasis"
                          href="#"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <small>
                            <i className="fa fa-download"></i> Download App
                          </small>
                        </a>
                        <ul className="dropdown-menu">
                          <li>
                            <a
                              target="_blank"
                              className="dropdown-item"
                              href="https://play.google.com/store/apps/details?id=com.equitypandit.live"
                            >
                              <i className="fa-brands fa-android"></i>{" "}
                              <small>Android</small>
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              className="dropdown-item"
                              href="https://apps.apple.com/in/app/equitypandit-lite/id6444849307"
                            >
                              <i className="fa-brands fa-apple"></i>{" "}
                              <small>iOS</small>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
            <div className="col-md-3 col-5 order-md-3 order-2 text-end">
              {suat && userDetail && userDetail.email ? (
                <div className="dropdown text-end">
                  <a
                    href="#"
                    className="ms-2 fw-semibold btn btn-success rounded-circle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {userDetail && userDetail.firstName.hasOwnProperty(0)
                      ? userDetail.firstName[0]
                      : ""}
                  </a>
                  <ul
                    className="dropdown-menu text-small py-0"
                    style={{
                      position: "absolute",
                      width: "200px",
                      inset: "0px 0px auto auto",
                      margin: "0px",
                      transform: "translate3d(0px, 46px, 0px)",
                    }}
                  >
                    <li>
                      <div
                        className="dropdown-item py-2 border-bottom uni-cursor-pointer"
                        onClick={() => handleRedirect("/dashboard")}
                      >
                        <ListIcon size={16} /> Dashboard
                      </div>
                    </li>
                    <li>
                      <div
                        className="dropdown-item py-2 border-bottom uni-cursor-pointer"
                        onClick={() => handleRedirect("/profile")}
                      >
                        <UserIcon size={18} /> My Profile
                      </div>
                    </li>
                    {/*<li>*/}
                    {/*  <div*/}
                    {/*    className="dropdown-item py-2 border-bottom uni-cursor-pointer"*/}
                    {/*    onClick={() => handleRedirect("/my-services")}*/}
                    {/*  >*/}
                    {/*    <GiftIcon size={16} /> My Services*/}
                    {/*  </div>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*  <div*/}
                    {/*    className="dropdown-item py-2 border-bottom uni-cursor-pointer"*/}
                    {/*    onClick={() => handleRedirect("/live-calls")}*/}
                    {/*  >*/}
                    {/*    <PhoneCallIcon size={16} /> Live Calls*/}
                    {/*  </div>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*  <div*/}
                    {/*    className="dropdown-item py-2 border-bottom uni-cursor-pointer"*/}
                    {/*    onClick={() => handleRedirect("/previous-calls")}*/}
                    {/*  >*/}
                    {/*    <PhoneIcon size={16} /> Previous Calls*/}
                    {/*  </div>*/}
                    {/*</li>*/}

                    <li>
                      <div
                        className="dropdown-item py-2 border-bottom uni-cursor-pointer"
                        onClick={() => handleRedirect("/support")}
                      >
                        <HeartHandshakeIcon size={16} /> Support
                      </div>
                    </li>
                    <li>
                      <button
                        className="dropdown-item py-2 border-bottom uni-cursor-pointer"
                        onClick={() => {
                          dispatch(clearSession());
                          dispatch(reduxLogout());
                          handleRedirect("/");
                        }}
                      >
                        <LogOutIcon size={14} /> Log Out
                      </button>
                    </li>
                  </ul>
                </div>
              ) : !authState ? (
                <>
                  <div
                    className="btn btn-success btn-sm me-3"
                    onClick={() => handleRedirect("/get-started")}
                  >
                    Get Started
                  </div>
                  <a
                    href="tel:8000816688"
                    className="border-start border-success ps-3 text-decoration-none text-black footer_link d-none d-md-inline-block"
                  >
                    <small>
                      <i className="fa fa-headset fa-flip-horizontal me-2"></i>
                      8000816688
                    </small>
                  </a>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <OffCanvasRight section="mobile" />
      </header>
    </>
  );
};

export default Header;
