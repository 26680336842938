"use client";
import React, {useEffect, useRef, useState} from "react";
import {
  activeServices,
  checkPaymentService,
  myServiceService,
} from "../service/MySubscription.service";
import moment from "moment";
import {ChevronRightIcon, Link2Icon, LinkIcon} from "lucide-react";
import axios from "axios";
import {ErrorMessage, Field, Form, Formik} from "formik";
import states from "./state.json";
import * as Yup from "yup";
import Layout from "../../layout/interface/Layout";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {V1_API_ORIGIN} from "../../../config/constants.config";

export default function MyService() {
  const paymentId = localStorage.getItem("pid") || null;
  const buttonRef = useRef(null);
  const authState = useSelector((state) => state.auth.authState);
  const [hasPackages, setHasPackages] = useState([]);
  const [hasKycData, setHasKycData] = useState({});
  const [hasPaymentId, setHasPaymentId] = useState(paymentId);
  const [isLastPaymentSuccess, setIsLastPaymentSuccess] = useState(false);
  const userDetail = useSelector((state) => state.auth.userDetail);
  const [serviceActivationMessage, setServiceActivationMessage] = useState({
    time: 0,
    message: "",
  });
  const [hasErrorMessage, setHasErrorMessage] = useState();

  const validationSchemaKyc = Yup.object({
    name: Yup.string()
      .required("Name is required")
      .max(255, "Name should be less then 100 characters."),
    mobile: Yup.string()
      .required("Mobile is required")
      .max(255, "Mobile should be less then 100 characters."),
    email: Yup.string()
      .required("Email is required")
      .max(255, "Email should be less then 100 characters."),
    city: Yup.string()
      .required("City is required")
      .max(255, "City should be less then 100 characters."),
    country: Yup.string()
      .required("Country is required")
      .max(255, "Country should be less then 100 characters."),
    pan: Yup.string()
      .required("PAN is required")
      .max(255, "PAN should be less then 100 characters."),
    address: Yup.string()
      .required("Address is required")
      .max(255, "Address should be less then 100 characters."),
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const uid = sessionStorage.getItem("uid");
      axios
        .get(`${V1_API_ORIGIN}/kyc/get-by-userId/${uid}`)
        .then((response) => {
          setHasKycData(response.data.data);
        })
        .catch(() => {
        });
    }
  }, []);

  useEffect(() => {
    myServiceService().then((response) => {
      setHasPackages(response.data.data);
    });
  }, [hasPaymentId]);

  useEffect(() => {
    if (hasPaymentId) {
      checkPaymentService(hasPaymentId).then((response) => {
        setIsLastPaymentSuccess(
          response.data.data?.digioDocSignStatus === "success" &&
          response.data.data?.payStatus === "success"
        );
      });
    }
  }, [hasPaymentId]);

  return (
    <Layout>
      <section className="bg-success bg-opacity-25">
        <div className="container py-5">
          <div className="row">
            {isLastPaymentSuccess && (
              <div className="col-lg-12 bg-info text-center py-2 rounded mb-3">
                <div className="mb-2">
                  Your last payment was successful, activate your service
                </div>
                {hasKycData?.id ? (
                  <button
                    className="btn btn-success"
                    onClick={() => {
                      activeServices(hasPaymentId).then(() => {
                        setIsLastPaymentSuccess(false);
                        setHasPaymentId("0");
                        localStorage.removeItem("pid");
                        // setServiceActivationMessage({
                        //     time: 2000,
                        //     message: 'Your service activated.'
                        // });
                        // setTimeout(() => {
                        //     setServiceActivationMessage({
                        //         ...serviceActivationMessage,
                        //         time: 0
                        //     })
                        // }, serviceActivationMessage.time);
                      });
                    }}
                  >
                    Activate
                  </button>
                ) : (
                  <button
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#service_process"
                    className="btn text-white rounded-1 py-1 px-3 continue btn-success"
                  >
                    Complete your KYC
                  </button>
                )}
              </div>
            )}
            <div className="col-md-12">
              <div className="bg-white rounded-3 p-3">
                <h3 className="fw-bold mb-4">My Services</h3>
                <hr/>
                <div className="row g-3">
                  {serviceActivationMessage.time <= 0 &&
                    (hasPackages || []).map((item, index) => (
                      <>
                        <div key={index} className="col-md-4">
                          <div className="card">
                            <div className="card-header">
                              <div className="d-flex justify-content-between align-items-start">
                                <div className="fw-bolder">{item.pacName}</div>
                                <small className="fw-bold">
                                  {item.status === "active" ? (
                                    <span className="text-success text-capitalize">
                                    active
                                  </span>
                                  ) : (
                                    <span className="text-danger text-capitalize">
                                    expired
                                  </span>
                                  )}
                                </small>
                              </div>
                            </div>
                            <div className="card-body bg-secondary bg-opacity-10">
                              <div className="d-flex justify-content-between pb-2">
                                <div>
                                  <small className="text-muted">Start Date</small>
                                  <div className="fs-14">
                                    {moment(item.serviceStartDate).format(
                                      "DD MMM, YYYY"
                                    )}
                                  </div>
                                </div>
                                <div>
                                  <small className="text-muted">End Date</small>
                                  <div>
                                    {moment(item.serviceEndDate).format(
                                      "DD MMM, YYYY"
                                    )}
                                  </div>
                                </div>
                                <div
                                  className={`${
                                    item.status === "expired" && "opacity-0"
                                  }`}
                                >
                                  <small className="text-muted">Days Left</small>
                                  <div>{item.dueDaysOfExpiry} Day(s)</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                </div>
              </div>
            </div>

            {hasPackages.length <= 0 && (
              <div className="w-100 bg-secondary-subtle text-center py-2 rounded">
                <div>No active service</div>
                <Link to="/services">
                  Buy a service <Link2Icon/>
                </Link>
              </div>
            )}
          </div>
        </div>
        <div
          className="modal fade"
          id="service_process"
          tabIndex="-1"
          aria-labelledby="service_processLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-md modal-confirm modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content table">
              <div className="modal-header">
                <h5 className="modal-title fw-bold">Complete KYC</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  ref={buttonRef}
                ></button>
              </div>
              <div className="modal-body pt-1 mb-2">
                <div className="row">
                  <Formik
                    initialValues={{
                      userId: userDetail.id,
                      ckycNo: "-",
                      name: userDetail.firstName,
                      email: userDetail.email,
                      mobile: userDetail.mobile,
                      address: "",
                      city: "",
                      state: "",
                      country: "",
                      pan: "",
                      isCompleted: true,
                    }}
                    validationSchema={validationSchemaKyc}
                    onSubmit={async (values, {setSubmitting}) => {
                      setSubmitting(false);
                      await axios.post(`${API_HOST}/kyc`, values);
                      let uid = '';
                      if (typeof window !== 'undefined') {
                        uid = sessionStorage.getItem("uid");
                      }
                      axios
                        .get(`${API_HOST}/kyc/get-by-userId/${uid}`)
                        .then((response) => {
                          setHasKycData(response.data.data);
                        })
                        .then(() => {
                          buttonRef.current.click();
                        });
                    }}
                    enableReinitialize
                  >
                    {({isSubmitting, touched, errors, values}) => (
                      <Form>
                        <div className="row">
                          {hasKycData?.id && (
                            <div className="col-12 mb-3 text-center bg-secondary-subtle py-2">
                              Your KYC details are up to date.
                            </div>
                          )}
                          <div className="col-6 mb-2">
                            <small className="d-block">Name</small>
                            <Field
                              name="name"
                              className={`form-control form-control-sm mt-1 ${
                                touched.name && errors.name && "is-invalid"
                              }`}
                              value={values.name || ""}
                            />
                            <ErrorMessage
                              name="name"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="col-6 mb-2">
                            <small className="d-block">Mobile</small>
                            <Field
                              name="mobile"
                              className={`form-control form-control-sm mt-1 ${
                                touched.mobile && errors.mobile && "is-invalid"
                              }`}
                              value={values.mobile || ""}
                            />
                            <ErrorMessage
                              name="mobile"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="col-6 mb-2">
                            <small className="d-block">Email</small>
                            <Field
                              name="email"
                              className={`form-control form-control-sm mt-1 ${
                                touched.email && errors.email && "is-invalid"
                              }`}
                              value={values.email || ""}
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="col-6 mb-2">
                            <small className="d-block">Country</small>
                            <Field
                              as="select"
                              name="country"
                              className={`form-control form-control-sm mt-1 ${
                                touched.country && errors.country && "is-invalid"
                              }`}
                              value={values.country || ""}
                            >
                              <option value="">-- Select --</option>
                              <option value="INDIA">INDIA</option>
                              <option value="">OTHER</option>
                            </Field>
                          </div>
                          {["INDIA"].includes(values.country) ? (
                            <div className="col-6 mb-2">
                              <small className="d-block">State</small>
                              <Field
                                as="select"
                                name="state"
                                className={`form-control form-control-sm mt-1 ${
                                  touched.state && errors.state && "is-invalid"
                                }`}
                                value={values.state || ""}
                              >
                                <option value="">-- Select --</option>
                                {states.map((item, index) => (
                                  <option value={item.value} key={index}>
                                    {item.text}
                                  </option>
                                ))}
                              </Field>
                              <ErrorMessage
                                name="state"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          ) : (
                            <div className="col-6 mb-2">
                              <small className="d-block">Country</small>
                              <Field
                                name="country"
                                className={`form-control form-control-sm mt-1 ${
                                  touched.country &&
                                  errors.country &&
                                  "is-invalid"
                                }`}
                                value={values.country || ""}
                              />
                              <ErrorMessage
                                name="country"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          )}

                          <div className="col-6 mb-2">
                            <small className="d-block">City</small>
                            <Field
                              name="city"
                              className={`form-control form-control-sm mt-1 ${
                                touched.city && errors.city && "is-invalid"
                              }`}
                              value={values.city || ""}
                            />
                            <ErrorMessage
                              name="city"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4 col-6">
                            <small style={{fontSize: "13px"}}>PAN</small>
                            <Field
                              name="pan"
                              className={`form-control form-control-sm mt-1 ${
                                touched.pan && errors.pan && "is-invalid"
                              }`}
                              value={values.pan || hasKycData.pan || ""}
                            ></Field>
                            <ErrorMessage
                              name="pan"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>

                          <div className="col-md-12 col-12">
                            <small style={{fontSize: "13px"}}>
                              Full Address (as per document)
                            </small>
                            <Field
                              name="address"
                              className={`form-control form-control-sm mt-1 ${
                                touched.address && errors.address && "is-invalid"
                              }`}
                              value={values.address || hasKycData.address || ""}
                            ></Field>
                            <ErrorMessage
                              name="address"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                        {!(userDetail.rpfProfileType && authState) && (
                          <div className="text-center text-danger">
                          <span>
                            <b>{hasErrorMessage}</b>
                          </span>
                            <br/>
                            <Link to="/risk-profile">
                              Please complete your risk profile first.{" "}
                              <LinkIcon size={15}/>
                            </Link>
                          </div>
                        )}
                        <div className="col-12 text-end">
                          <div className="my-2">
                            <button
                              type="submit"
                              className="btn btn-success px-3 w-100"
                              disabled={
                                isSubmitting ||
                                (!userDetail.rpfProfileType && authState)
                              }
                            >
                              {isSubmitting ? "Loading..." : "Proceed"}
                              {!isSubmitting && <ChevronRightIcon size={20}/>}
                              <i className="bi bi-arrow-right"></i>
                            </button>
                          </div>
                          <p></p>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}
