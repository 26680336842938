import React from 'react';
import Layout from "../layout/interface/Layout";
import {useDocumentTitle} from "../../components/Common";

const ComplianceAudit = () => {
  useDocumentTitle("Compliance Audit");
  return (
    <Layout>
      <section className="my-3">
        <div className="container py-lg-5 pt-5 pb-0">
          <div className="col-md-12 pt-lg-2">
            <div
              className="fs-2 text-black text-center fw-bold mx-3 px-3 mx-lg-5 px-lg-4 mx-md-3 px-md-3">Compliance
              Audit
            </div>
            <br/>
            <br/>
            <div className="mx-3 px-3 mx-lg-5 px-lg-4 mx-md-3 px-md-3">
              <table className="table border table-bordered text-center">
                <thead>
                <tr>
                  <th>S. No</th>
                  <th>Financial Year</th>
                  <th>Compliance Audit Status</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>1</td>
                  <td>FY 2020-21</td>
                  <td>Conducted</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>FY 2021-22</td>
                  <td>Conducted</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>FY 2022-23</td>
                  <td>Conducted</td>
                </tr>
                </tbody>
              </table>
            </div>
            <br/>
            <br/>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ComplianceAudit;
