import { Link, useNavigate } from "react-router-dom";
import {APP_URL} from "../../../config/constants.config";
import {useEffect, useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {handleScrollToTop} from "../../../components/Common";
const Footer = () => {
  const [GetTheme, setGetTheme]=useState("dark");
  const router = useNavigate();

  function handleRedirect(link) {
    router(link);
    handleScrollToTop();
  }

  useEffect(() => {
    setGetTheme(localStorage.getItem("theme"));
  }, []);
  return (
    <>
      <footer id="footer" className="border-top border-5 border-success">
        <section>
          <div className="container mt-5 mb-4">
            <div className="row g-3">
              <div className="col-md-4">
                <img
                  style={{ width: "100%", maxWidth: "225px" }}
                  src="/ep-imgs/EP-Investment-Advisor.png"
                  alt="Equitypandit Investment Advisor"
                />
                <br />
                <br />
                <p>
                  <small>
                    Address: A-488, Vidhyut Nagar, Ajmer Road, <br />
                    Jaipur, Rajasthan -302019
                  </small>
                </p>
                <p>
                  <small>Phone: +91 8000 816 688</small>
                </p>
                <p>
                  <small>Email: support@equitypandit.com</small>
                </p>
                <div className="social-links mt-3">
                  <a
                    href="https://twitter.com/theequitypandit?lang=en"
                    target={"_blank"}
                    className="twitter"
                  >
                    <FontAwesomeIcon
                      icon={faTwitter}
                      style={{ width: "35px", fontSize: "22px" }}
                    />
                  </a>
                  <a
                    href="https://www.facebook.com/equitypandit/"
                    target={"_blank"}
                    className="facebook"
                  >
                    <FontAwesomeIcon
                      icon={faFacebook}
                      style={{ width: "35px", fontSize: "22px" }}
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/equitypandit_official/"
                    target={"_blank"}
                    className="instagram"
                  >
                    <FontAwesomeIcon
                      icon={faInstagram}
                      style={{ width: "35px", fontSize: "22px" }}
                    />
                  </a>
                </div>
              </div>
              <div className="col-md-2 col-6">
                <h6 className="fw-bold text-body-emphasis">Company</h6>
                <ul className="nav flex-column">
                  <li className="nav-item mb-2">
                    <small>
                      <div className="nav-link p-0 text-body-secondary uni-cursor-pointer" onClick={()=> handleRedirect("/")}>
                        Home
                      </div>
                    </small>
                  </li>
                  <li className="nav-item mb-2">
                    <small>
                      <div
                        className="nav-link p-0 text-body-secondary uni-cursor-pointer"
                        onClick={()=> handleRedirect('/the-company')}
                        // href="/the-company"
                      >
                        About Us
                      </div>
                    </small>
                  </li>
                  <li className="nav-item mb-2">
                    <small>
                      <div
                        className="nav-link p-0 text-body-secondary uni-cursor-pointer"
                        onClick={() => handleRedirect("/careers")}
                      >
                        Careers
                      </div>
                    </small>
                  </li>
                  <li className="nav-item mb-2">
                    <small>
                      <div
                        className="nav-link p-0 text-body-secondary uni-cursor-pointer"
                        onClick={()=> handleRedirect("/contact-us")}
                      >
                        Contact Us
                      </div>
                    </small>
                  </li>
                </ul>
              </div>
              <div className="col-md-2 col-6">
                <h6 className="fw-bold text-body-emphasis">Services</h6>
                <ul className="nav flex-column">
                  <li className="nav-item mb-2">
                    <small>
                      <div
                        className="nav-link p-0 text-body-secondary uni-cursor-pointer"
                        onClick={()=> handleRedirect("/services/trading-investing")}
                      >
                        Investing & Trading
                      </div>
                    </small>
                  </li>
                  <li className="nav-item mb-2">
                    <small>
                      <div
                        className="nav-link p-0 text-body-secondary uni-cursor-pointer"
                        onClick={()=> handleRedirect("/services/institutional-investors")}
                      >
                        Institutional Investors
                      </div>
                    </small>
                  </li>
                  <li className="nav-item mb-2">
                    <small>
                      <div
                        className="nav-link p-0 text-body-secondary uni-cursor-pointer"
                        onClick={()=> handleRedirect("/services/hni-uhni")}
                      >
                        HNI & UHNI Services
                      </div>
                    </small>
                  </li>
                </ul>
              </div>
              <div className="col-md-2 col-6">
                <h6 className="fw-bold text-body-emphasis">Download App</h6>
                <ul className="nav flex-column">
                  <li className="nav-item mb-2">
                    <small>
                      <a className="nav-link p-0 text-body-secondary" href="https://play.google.com/store/apps/details?id=com.equitypandit.live">
                        Android
                      </a>
                    </small>
                  </li>
                  <li className="nav-item mb-2">
                    <small>
                      <a className="nav-link p-0 text-body-secondary" href="https://apps.apple.com/in/app/equitypandit-lite/id6444849307">
                        IOS
                      </a>
                    </small>
                  </li>
                </ul>
              </div>
              <div className="col-md-2 col-6">
                <h6 className="fw-bold text-body-emphasis">Legal & Privacy</h6>
                <ul className="p-0" style={{listStyle: "none"}}>
                  <li className="nav-item mb-2">
                    <Link
                      className="nav-link p-0 text-body-secondary"
                      to="/terms-conditions"
                    >
                      Terms and Conditions
                    </Link>
                  </li>
                  <li className="nav-item mb-2">
                    <Link
                      className="nav-link p-0 text-body-secondary"
                      target="_blank"
                      to="/assets/footer/Grievance-Redressal-Mechanism.pdf"
                    >
                      Grievance Mechanism
                    </Link>
                  </li>
                  <li className="nav-item mb-2">
                    <div
                      className="nav-link p-0 text-body-secondary uni-cursor-pointer"
                      onClick={()=> handleRedirect("/privacy-policy")}
                    >
                      Privacy Policy
                    </div>
                  </li>
                  <li className="nav-item mb-2">
                    <a
                      className="nav-link p-0 text-body-secondary"
                      target="_blank"
                      href="/assets/footer/EquityPandit-Disclosure.pdf"
                    >
                      Disclosure
                    </a>
                  </li>
                  <li className="nav-item mb-2">
                    <div
                      className="nav-link p-0 text-body-secondary uni-cursor-pointer"
                      onClick={() => handleRedirect("/investment-charter")}
                    >
                      Investor Charter
                    </div>
                  </li>
                  <li className="nav-item mb-2">
                    <a
                      className="nav-link p-0 text-body-secondary"
                      target="_blank"
                      href="/assets/footer/investor-grievance.pdf"
                    >
                      Investor Grievance
                    </a>
                  </li>
                  <li className="nav-item mb-2">
                    <div
                      className="nav-link p-0 text-body-secondary uni-cursor-pointer"
                      onClick={()=> handleRedirect("/compliance-audit")}
                    >
                      Compliance Audit
                    </div>
                  </li>
                </ul>
              </div>

              <div className="col-12">
                <hr />
              </div>
              <div className="col-md-3 text-muted">
                <p>
                  <small className="fw-bold">
                    SEBI Registered Investment Adviser Details:
                  </small>
                  <br />
                  <small>
                    Registered Name: Equitypandit Financial Services Private
                    Limited
                  </small>
                  <br />
                  <small>Type of registration: Non-Individual</small>
                  <br />
                  <small>Registration No.: INA000006688</small>
                  <br />
                  <small>Validity: Perpetual</small>
                  <br />
                  <small>
                    <Link to={""} className="text-muted text-decoration-none">
                      View Registration Certificate
                    </Link>
                  </small>
                </p>
              </div>
              <div className="col-md-9">
                <div className="text-muted">
                  <small className="mt-3">
                    Principal Officer: Kamlesh Sachar | Email:
                    po@equitypandit.co.in | Contact No.: +91 8000816688 | SEBI
                    LO.: Ground Floor, Jeevan Nidhi 2 LIC Building, Ambedkar
                    Circle, Bhawani Singh Rd, Jaipur, Rajasthan 302005
                  </small>
                  <small className="mt-3 d-none">
                    Disclaimer : There is no guarantee of profits or no
                    exceptions from losses. The investment advice provided is
                    solely the personal views of the research team. Equitypandit
                    will not accept any liability for loss or damage as a result
                    of reliance on the information contained within this website
                    including data, quotes, charts and buy/sell signals. Please
                    be fully informed regarding the risks and costs associated
                    with trading the financial markets, it is one of the
                    riskiest investment forms possible. Therefore, Equitypandit
                    doesn’t bear any responsibility for any trading losses you
                    might incur as a result of using this data. *KYC is
                    mandatory for all Subscriptions.
                  </small>
                  <p className="mb-0 mt-3">
                    <small>Disclaimers:</small>
                  </p>
                  <ol>
                    <li>
                      <small>
                        Registration granted by SEBI, membership of BASL and
                        certification from NISM in no way guarantee performance
                        of the intermediary or provide any assurance of returns
                        to investors.
                      </small>
                    </li>
                    <li>
                      <small>
                        The securities quoted, if any are for illustration only
                        and are not recommendatory.
                      </small>
                    </li>
                    <li>
                      <small>
                        Investments in securities market are subject to market
                        risks. Read all the related documents carefully before
                        investing.
                      </small>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>
      </footer>

      <div className="bg-dark text-light text-center p-2">
        <small>
          © Copyright 2024{" "}
          <Link to={"https://equitypandit.co.in"}>equitypandit.co.in </Link>
          Equitypandit Financial Services Private Limited. All Rights Reserved
        </small>
      </div>
    </>
  );
};

export default Footer;
