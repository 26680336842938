import {
  CheckIcon,
  IndianRupee,
  Mail,
  PhoneIcon,
  SquareArrowOutUpRight,
  Zap,
} from "lucide-react";
import { MakePaymentProcess } from "./MakePaymentProcess";
import PlaneCompare from "../pages/service/trading-investing/PlaneCompare";
import TradingInvestingFAQ from "../pages/service/trading-investing/TradingInvestingFAQ";
import React, { useEffect, useState } from "react";
import {
  getBundleService,
  getPackagesService,
} from "../pages/service/trading-investing/TradingInvesting.service";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { handleScrollToTop } from "./Common";

export default function TradingInvestingPlans() {
  const userDetail = useSelector((state) => state.auth.userDetail);
  const [hasPackages, setHasPackages] = useState([]);
  const [hasBundles, setHasBundles] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [isOpen, setIsOpen] = useState({
    showFlag: false,
    index: 0,
  });
  const [selectedOption, setSelectedOption] = useState(null);
  const [canSelectPlan, setCanSelectPlan] = useState([
    "Conservative",
    "Moderate",
    "Aggressive",
  ]);
  const navigate = useNavigate();

  function handleRedirect(link) {
    navigate(link);
    handleScrollToTop();
  }

  useEffect(() => {
    if (userDetail.rpfProfileType === "Conservative")
      setCanSelectPlan(["Conservative"]);
    else if (userDetail.rpfProfileType === "Moderate")
      setCanSelectPlan(["Conservative", "Moderate"]);
    else if (userDetail.rpfProfileType === "Aggressive")
      setCanSelectPlan(["Conservative", "Moderate", "Aggressive"]);
    else if (!userDetail.rpfProfileType)
      //if rpf not filled
      setCanSelectPlan(["Conservative", "Moderate", "Aggressive"]);
  }, [userDetail.rpfProfileType]);

  useEffect(() => {
    getPackagesService().then((response) => {
      setHasPackages(response.data.data);
    });
    getBundleService().then((response) => {
      setHasBundles(response);
    });
  }, []);
  const handleSelect = (option, item, index) => {
    setSelectedOption(option);
    setIsOpen({ showFlag: false, index: index });

    const newPlanToAdd = option === "" ? undefined : option;
    setSelectedPlan((prevSelectedPlan) => {
      const updatedSelectedPlan = {};
      if (newPlanToAdd !== undefined) {
        if (item === 1000) {
          updatedSelectedPlan[1000] = {
            id: option.id,
            bundleName: option.bundleName,
            planDuration: option.duration,
            description: "power plus to 10x your annual return.",
            firebaseName: "powerplus",
          };
          window.total = option.discountedPrice;
          window.calculatedGst = option.discountedPrice * 0.18;
          window.grandTotal = option.discountedPrice + window.calculatedGst;
        } else {
          updatedSelectedPlan[item.id] = {
            ...newPlanToAdd,
            name: item.name,
            description: item.description,
            firebaseName: item.firebaseName,
          };
          window.total = newPlanToAdd.discountPrice;
          window.calculatedGst = newPlanToAdd.discountPrice * 0.18;
          window.grandTotal = newPlanToAdd.discountPrice + window.calculatedGst;
        }
      } else {
        delete updatedSelectedPlan[item === 1000 ? 1000 : item.id];
        setSelectedOption(null);
      }
      return updatedSelectedPlan;
    });
  };

  return (
    <>
      <section className="bg-green bg-opacity-10 shadow text-body-emphasis">
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center p-4">
                <h1 className="fw-bold">
                  All your trading & investing needs in one place
                </h1>
                <h5 className="fw-normal">
                  Power your trades and Investments with our most comprehensive
                  Premium Bundle
                </h5>
              </div>
            </div>

            {hasPackages ? (
              hasPackages.map((item, index) => {
                return item.showHide &&
                  item.plans.filter((item) => item.showHide).length > 0 &&
                  canSelectPlan.find((option) => option === item.rpfType) ? (
                  <div className="col-lg-6 mb-3" key={index}>
                    <div className="bg-white rounded-3 border">
                      <div
                        className="p-3 rounded-top border-bottom"
                        style={{
                          backgroundColor: `${
                            item.investmentType === "INTRADAY"
                              ? "#DFF64D00"
                              : item.investmentType === "INVESTMENT"
                              ? "#17C2A900"
                              : "#6DCDFE00"
                          }`,
                        }}
                      >
                        <div className="row g-3 justify-content-between">
                          <div className="col-7">
                            <a className="d-flex align-items-center text-decoration-none">
                              <h4 className="text-black fw-bold mb-0 me-2">
                                {item.name}
                              </h4>
                            </a>
                            <small className="text-muted">
                              Go Premium Get Awesome
                            </small>
                          </div>
                          <div className="col-5 text-end">
                            <small
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                navigate(
                                  `/services/trading-investing/${
                                    item.slug ? item.slug : item.id
                                  }`
                                );
                              }}
                            >
                              <SquareArrowOutUpRight
                                className="text-dark"
                                size={20}
                              />{" "}
                              View Details
                            </small>
                          </div>
                          <div className="col-12">
                            <hr className="border border-success border-3 my-0" />
                            <div className="pt-3">
                              <div className="d-flex fs-12 text-black fw-medium mb-2">
                                {item.investmentType !== "-" && (
                                  <small className="me-3 px-2 py-1 rounded-1 bg-success bg-opacity-10">
                                    {item.investmentType}
                                  </small>
                                )}
                              </div>

                              <small
                                className="my-3"
                                style={{ color: "#414141" }}
                              >
                                {item.description}
                              </small>
                            </div>
                            {!canSelectPlan.find(
                              (option) => option === item.rpfType
                            ) ? (
                              <small className="text-danger">
                                This package is not suitable with your risk
                                profile.
                              </small>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-md-8">
                            <select
                              disabled={
                                !canSelectPlan.find(
                                  (option) => option === item.rpfType
                                )
                              }
                              className="form-select shadow-none d-inline-block d-none"
                              style={{ border: "1px solid #8d8d8d" }}
                              aria-label="Default select example"
                              value={selectedPlan[item.id]?.id || ""}
                              onChange={async (e) => {
                                const newPlanToAdd = item.plans.find(
                                  (option) => option.id == e.target.value
                                );
                                window.total = newPlanToAdd.discountPrice;
                                window.calculatedGst =
                                  newPlanToAdd.discountPrice * 0.18;
                                window.grandTotal =
                                  newPlanToAdd.discountPrice +
                                  window.calculatedGst;
                                setSelectedPlan(() => {
                                  const updatedSelectedPlan = {};
                                  if (newPlanToAdd !== undefined) {
                                    updatedSelectedPlan[item.id] = {
                                      ...newPlanToAdd,
                                      name: item.name,
                                      description: item.description,
                                      firebaseName: item.firebaseName,
                                    };
                                  } else {
                                    delete updatedSelectedPlan[item.id];
                                  }

                                  return updatedSelectedPlan;
                                });
                              }}
                            >
                              <option className="text-center" value="">
                                Select a Plan
                              </option>
                              {item.plans.length > 0 &&
                                item.plans.map(
                                  (a, i) =>
                                    a.showHide && (
                                      <option
                                        className="text-center"
                                        value={a.id}
                                        key={i}
                                      >
                                        {a.planDuration}{" "}
                                        {a.planDuration === 1
                                          ? "Month"
                                          : "Months"}{" "}
                                        -{" "}
                                        {a.discountPrice.toLocaleString(
                                          "en-US"
                                        )}
                                      </option>
                                    )
                                )}
                            </select>

                            <div
                              className="custom-dropdown form-select"
                              style={{ border: "1px solid rgb(141, 141, 141)" }}
                            >
                              <div
                                className="text-center"
                                onClick={() => {
                                  setIsOpen(() => {
                                    let newFlag = 0;
                                    if (isOpen.index === index) {
                                      newFlag = !isOpen.showFlag;
                                    } else {
                                      newFlag = true;
                                    }
                                    return {
                                      showFlag: newFlag,
                                      index,
                                    };
                                  });
                                  setSelectedOption(null);
                                }}
                              >
                                {selectedOption && index === isOpen.index ? (
                                  <>
                                    <b>
                                      {selectedOption.planDuration}{" "}
                                      {selectedOption.planDuration === 1
                                        ? "Month"
                                        : "Months"}{" "}
                                    </b>
                                    <s>
                                      {" "}
                                      <IndianRupee size={13} />
                                      {selectedOption.actualPrice}
                                    </s>{" "}
                                    <IndianRupee size={13} />
                                    {selectedOption.discountPrice}
                                  </>
                                ) : (
                                  "Select a plan"
                                )}
                              </div>
                              {isOpen.showFlag && index === isOpen.index && (
                                <div className="options-list">
                                  <div
                                    className="option"
                                    onClick={() =>
                                      handleSelect("", item, index)
                                    }
                                  >
                                    Select a plan
                                  </div>
                                  {item.plans.length > 0 &&
                                    item.plans.map((option, i) => (
                                      <div
                                        key={i}
                                        className="option"
                                        onClick={() =>
                                          handleSelect(option, item, index)
                                        }
                                      >
                                        {option.planDuration}{" "}
                                        {option.planDuration === 1
                                          ? "Month"
                                          : "Months"}
                                        <s>
                                          {" "}
                                          <IndianRupee size={13} />
                                          {option.actualPrice}
                                        </s>{" "}
                                        <IndianRupee size={13} />
                                        {option.discountPrice}
                                      </div>
                                    ))}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <MakePaymentProcess
                              planData={selectedPlan}
                              componentKey={item.id}
                              total={window.total}
                              gst={window.calculatedGst}
                              grandTotal={window.grandTotal}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                );
              })
            ) : (
              <div>Loading...</div>
            )}

            <div className="col-lg-12 mt-md-2">
              <div className="shadow bg-white p-4 rounded-3">
                <div className="row g-3 align-items-center">
                  <div className="col-md-12">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <Zap className="" size={24} />
                        <div className="ms-2">
                          <h4 className="fw-bold mb-0">Power Plus</h4>
                          <small className="text-muted">(Passionate Trader + Intelligent Investor)</small>
                        </div>
                      </div>
                      <div>
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            location.href = `/services/trading-investing/power-plus`;
                          }}
                        >
                          <SquareArrowOutUpRight size={18} /> View Details
                        </a>
                      </div>
                    </div>
                    <hr className="border border-success border-3" />
                  </div>
                  <div className="col-md-12">
                    <div className="d-flex align-items-center mb-3">
                      <p className="mb-0 fw-bold">Profile Type</p>
                      <small className="ms-2 mb-0 px-2 rounded-3 bg-success bg-opacity-25">
                        Aggressive
                      </small>
                    </div>
                    <ul className="list-unstyled">
                      <li className="my-2">
                        <CheckIcon className="text-success" /> Herein we will
                        provide you Option Segment Calls Daily
                      </li>
                      <li className="my-2">
                        <CheckIcon className="text-success" /> Nifty Review,
                        Resistance & Support
                      </li>
                      <li className="my-2">
                        <CheckIcon className="text-success" /> Pre-Market trend
                        of Sensex and Nifty
                      </li>
                      <li className="my-2">
                        <CheckIcon className="text-success" /> Follow Ups & All
                        Important News & Information
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-4">
                    <select
                      className="form-select shadow-none d-inline-block d-none"
                      style={{ border: "1px solid #8d8d8d" }}
                      value={selectedPlan[1000]?.id || ""}
                      onChange={async (e) => {
                        const parsedValue = hasBundles.find(
                          (option) => option?.id === parseInt(e.target.value)
                        );
                        window.total = parsedValue.discountedPrice;
                        window.calculatedGst =
                          parsedValue.discountedPrice * 0.18;
                        window.grandTotal =
                          parsedValue.discountedPrice + window.calculatedGst;
                        setSelectedPlan(() => {
                          const updatedSelectedPlan = {};
                          updatedSelectedPlan[1000] = {
                            id: parsedValue.id,
                            bundleName: parsedValue.bundleName,
                            planDuration: parsedValue.duration,
                            description:
                              "power plus to 10x your annual return.",
                            firebaseName: "powerplus",
                          };
                          return updatedSelectedPlan;
                        });
                      }}
                    >
                      <option>Select a Plan</option>
                      {hasBundles.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.duration} Months{" - "}
                            {item.discountedPrice.toLocaleString("en-US")}
                          </option>
                        );
                      })}
                    </select>

                    <div
                      className="custom-dropdown form-select"
                      style={{ border: "1px solid rgb(141, 141, 141)" }}
                    >
                      <div
                        className="text-center"
                        onClick={() => {
                          setIsOpen({
                            showFlag: !isOpen.showFlag,
                            index: 1000,
                          });
                          setSelectedOption(null);
                        }}
                      >
                        {selectedOption && 1000 === isOpen.index ? (
                          <>
                            <b>
                              {selectedOption.duration}{" "}
                              {selectedOption.duration === 1
                                ? "Month"
                                : "Months"}{" "}
                            </b>
                            <s>
                              {" "}
                              <IndianRupee size={13} />
                              {selectedOption.actualPrice}
                            </s>{" "}
                            <IndianRupee size={13} />
                            {selectedOption.discountedPrice}
                          </>
                        ) : (
                          "Select a plan"
                        )}
                      </div>
                      {isOpen.showFlag && 1000 === isOpen.index && (
                        <div className="options-list">
                          <div
                            className="option"
                            onClick={() => handleSelect("", 1000, 1000)}
                          >
                            Select a plan
                          </div>
                          {hasBundles.length > 0 &&
                            hasBundles.map((option, i) => (
                              <div
                                key={i}
                                className="option"
                                onClick={() => handleSelect(option, 1000, 1000)}
                              >
                                {option.duration}{" "}
                                {option.duration === 1 ? "Month" : "Months"}
                                <s>
                                  {" "}
                                  <IndianRupee size={13} />
                                  {option.actualPrice}
                                </s>{" "}
                                <IndianRupee size={13} />
                                {option.discountedPrice}
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <MakePaymentProcess
                      planData={selectedPlan}
                      total={window.total}
                      gst={window.calculatedGst}
                      grandTotal={window.grandTotal}
                      componentKey={1000}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 text-center mt-3">
            <small className="text-muted">
              Note: Prices are exclusive of GST at 18%
            </small>
          </div>
        </div>
      </section>

      <PlaneCompare />

      <TradingInvestingFAQ />

      <section className="bg-green bg-opacity-10">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center py-5">
                <h1 className="fw-bold">
                  Need any help? Contact To Support Team!
                </h1>
                <p>
                  We are always there for you every time and will guide you at
                  every step. <br />
                  The team of certified advisors will assist you in achieving
                  the wealth creation goal.
                </p>
                <div className="text-success justify-content-center fw-bold">
                  <p>
                    <PhoneIcon size={18} /> +91 8000 816 688
                  </p>
                  <p>
                    <Mail size={18} /> support@equitypandit.com
                  </p>
                </div>
                <button
                  className="btn btn-dark"
                  onClick={() => handleRedirect("/contact-us")}
                >
                  Contact Us
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
