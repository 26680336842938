import {Route} from "react-router";
import {MakePayment} from "./pages/make-payment/MakePayment";

const allRoutes = [
  {
    path: "/make-payment",
    component: <MakePayment />
  }
];

export default allRoutes.map((row, i) => (<Route
  path={row.path} key={i}
  element={row.component}>
</Route>));

