import React, {useEffect, useState} from 'react';
import {getPackagesService} from "../intelligent-investor/IntelligentInvestor.service";
import {useDocumentTitle} from "../../../../components/Common";
import * as Yup from "yup";
import Layout from "../../../layout/interface/Layout";
import {CheckIcon, IndianRupee, MoveRightIcon, PhoneCallIcon} from "lucide-react";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {getBundleService, submitLead} from "../TradingInvesting.service";
import PlaneCompare from "../PlaneCompare";
import {MakePaymentProcess} from "../../../../components/MakePaymentProcess";
import TradingInvestingFAQ from "../TradingInvestingFAQ";

const PowerPlus = () => {
  useEffect(() => {
    getBundleService().then((response) => {
      setHasPackages(response);
    });
  }, []);
  const [hasCart, setHasCart] = useState({
    total: 0,
    gst: 0,
    grandTotal: 0,
  });
  const [selectedPlan, setSelectedPlan] = useState({});
  const [hasPackages, setHasPackages] = useState([]);
  const [successMessage, setSuccessMessage] = useState();
  const [failedMessage, setFailedMessage] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  useDocumentTitle('Power Plus');
  const validationSchema = Yup.object({
    firstName: Yup.string()
      .required('Name is required')
      .max(150, 'Name should be 150 characters only.'),
    email: Yup.string()
      .required('Email is required')
      .max(150, 'Email should be 150 characters only.'),
    mobile: Yup.string()
      .required('Mobile is required')
      .min(10, 'Mobile must be 10 digits.')
      .max(10, 'Mobile must be 10 digits.'),
  });

  useEffect(() => {
    setIsOpen(false);
    setHasCart(() => {
      let total = Object.values(selectedPlan).reduce((total, plan) => {
        return parseFloat(plan.discountedPrice);
      }, 0);
      let gst = total * 0.18;
      let grandTotal = total + gst;
      return {
        total,
        gst: gst.toLocaleString(),
        grandTotal: Math.ceil(grandTotal).toLocaleString()
      };
    });
  }, [selectedPlan]);
  const handleSelect = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    const newPlanToAdd = option === "" ? undefined : option
    setSelectedPlan((prevSelectedPlan) => {
      const updatedSelectedPlan = {
        ...prevSelectedPlan,
      };
      if (newPlanToAdd !== undefined) {
        updatedSelectedPlan[1000] = {
          ...newPlanToAdd,
        };
        window.total = newPlanToAdd.discountedPrice;
        window.calculatedGst = newPlanToAdd.discountedPrice * 0.18;
        window.grandTotal = newPlanToAdd.discountedPrice + window.calculatedGst;
      } else {
        delete updatedSelectedPlan[1000];
        setSelectedOption(null);
      }
      return updatedSelectedPlan;
    });
    // onChange(option);
  };
  return (
    <Layout>
      <section className="p-0">
        <div className="container-fluid mb-5" style={{backgroundImage: `url('/assets/img/bg.png')`}}>
          <div className="container">
            <div className="row py-5 align-items-center">
              <div className="col-md-8">
                <div className="p-3 ps-3 mb-4 mt-4 text-black border-0 rounded-3 fs-18">
                  <h1 className="fw-bold text-uppercase">{hasPackages[0]?.bundleName}</h1>
                  <div
                    className="my-3">{hasPackages[0]?.description}
                  </div>
                  <button className="btn btn-md btn-success ">Get Started <MoveRightIcon size={20}/></button>
                </div>
              </div>
              <div className="col-md-4 mt-3 mb-3">
                <div className="text-end col-12 rounded-3 box_shadow p-1 bg-white">
                  <Formik
                    initialValues={{
                      firstName: '',
                      email: '',
                      countryCode: '+91',
                      mobile: '',
                      page: 'intelligent-investor',
                      source: 'equityPandit',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, {setSubmitting, resetForm}) => {
                      resetForm();
                      setSubmitting(true);
                      submitLead(values)
                        .then(res => {
                          setSuccessMessage(res.data.message);
                        })
                        .catch(e => {
                          setFailedMessage(e.response.data.message)
                        });
                    }}
                    enableReinitialize
                  >
                    {({isSubmitting, touched, errors, values}) => (
                      <Form className="mx-md-4 my-md-3">
                        <div className="row">
                          <div className="col-12 ">
                            <div className="my-3 text-center fw-bolder fs-18">Fill Up The Form</div>
                          </div>

                          {successMessage &&
                            <div className="text-center mb-2 text-success">
                              <small><b>{successMessage}</b></small>
                            </div>}

                          {failedMessage &&
                            <div className="text-center mb-2 text-danger">
                              <small><b>{failedMessage}</b></small>
                            </div>}

                          <div className="col-12 mt-3">
                            <Field
                              name="firstName"
                              placeholder="Name*"
                              className={`form-control rounded-3 py-2 px-3 show-tick ${touched.firstName && errors.firstName ? 'is-invalid' : 'border-dark-subtle'}`}
                              value={values.firstName}
                            />
                            <ErrorMessage name="firstName" component="div" className="invalid-feedback"/>
                          </div>
                          <div className="col-12 mt-3">
                            <Field
                              name="email"
                              placeholder="Email*"
                              className={`form-control rounded-3 py-2 px-3 show-tick ${touched.email && errors.email ? 'is-invalid' : 'border-dark-subtle'}`}
                              value={values.email}
                            />
                            <ErrorMessage name="email" component="div" className="invalid-feedback"/>
                          </div>
                          <div className="col-12 mt-3">
                            <Field
                              name="mobile"
                              placeholder="Mobile*"
                              className={`form-control rounded-3 py-2 px-3 show-tick ${touched.mobile && errors.mobile ? 'is-invalid' : 'border-dark-subtle'}`}
                              value={values.mobile}
                            />
                            <ErrorMessage name="mobile" component="div" className="invalid-feedback"/>
                          </div>
                          <div className="col-12">
                            <div className="text-center my-lg-0 my-2">
                              <button
                                type="submit"
                                disabled={isSubmitting}
                                className="btn btn-default text-white py-2 px-4 mt-2 bg-success">
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
                {/*</div>*/}
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container mb-5">
        <div className="row">
          <div className="col-md-8">
            <div className="">
              <h1 className="fw-bold text-uppercase">
                {hasPackages[0]?.bundleName}
              </h1>
              <small className="">Suitable Risk Profile </small>
              <small className="bg-success bg-opacity-50 px-2 py-1 rounded-3">{hasPackages[0]?.rpfType}</small>
              <p className="mt-3 fs-18">
                {hasPackages[0]?.description}
              </p>
              <hr className="my-4"/>
              <h3 className="fw-bold">What you get?</h3>
              <ul className="list-unstyled">
                <li className="my-2 fs-18"><CheckIcon/> Herein we will provide you Intraday recommendations in Index
                  Options.
                </li>
                <li className="my-2 fs-18"><CheckIcon/> Follow Ups & All Important News & Information.</li>
                <li className="my-2 fs-18"><CheckIcon/> Nifty Review, Resistance & Support.</li>
                <li className="my-2 fs-18"><CheckIcon/> Pre-Market trend of Sensex and Nifty.</li>
              </ul>
              <hr className="my-4"/>
              <h3 className="fw-bold">Trader Profile: Aggressive</h3>
              <ul className="list-unstyled">
                <li className="my-2 fs-18"><CheckIcon/> Full time / Part time traders can subscribe this package.</li>
                <li className="my-2 fs-18"><CheckIcon/> Should execute all the trades on time.</li>
                <li className="my-2 fs-18"><CheckIcon/> Should have access to Email / Phone.</li>
                <li className="my-2 fs-18"><CheckIcon/> Minimum Investment Suggested: Rs.30,000.</li>
              </ul>
              <hr className="my-4"/>
              <h3 className="fw-bold">Terms of Agreement:</h3>
              <ul className="list-unstyled">
                <li className="my-2 fs-18"><CheckIcon/> Full time / Part time traders can subscribe this package.</li>
                <li className="my-2 fs-18"><CheckIcon/> Should execute all the trades on time.</li>
                <li className="my-2 fs-18"><CheckIcon/> Should have access to Email / Phone.</li>
                <li className="my-2 fs-18"><CheckIcon/> Minimum Investment Suggested: Rs.30,000.</li>
              </ul>
            </div>
          </div>
          <div className="col-md-4">
            <div className="text-center rounded-3 border bg-white z-0 " style={{top: "15%"}}>
              <div className="py-3 bg-green">
                <h4 className="text-uppercase fw-bold">{hasPackages[0]?.bundleName}</h4>
                <p className="pb-0 mb-0 fw-normal">Go Premium Get Awesome</p>

              </div>
              <div className="p-3">
                <div className="d-flex justify-content-center">
                  <span className="badge bg-secondary-subtle rounded-2 fw-bold text-black">DERIVATIVES</span>
                  <span
                    className="badge ms-3 bg-primary-subtle rounded-2 fw-bold text-black text-uppercase">intraday</span>
                </div>

                <div className="mt-3 mb-2 justify-content-center fw-bold fs-5 text-black">
                  {/*{hasPackages ? (*/}
                  {/*  hasPackages.map((item, index) => {*/}
                  {/*    return item.showHide &&*/}
                  {/*      // <div className="" key={index}>*/}
                          <div className="custom-dropdown">
                            <div
                              className="text-center"
                              onClick={() => setIsOpen(!isOpen)}
                            >
                              {selectedOption ? (
                                <>
                                  <b>{selectedOption.duration} {selectedOption.duration === 1 ? 'Month' : 'Months'} </b>
                                  <s> <IndianRupee size={13} />{selectedOption.actualPrice}</s> <IndianRupee size={13} />{selectedOption.discountedPrice}
                                </>
                              ) : (
                                "Select a plan"
                              )}
                            </div>
                            {isOpen && (
                              <div className="options-list">
                                <div className="option" onClick={() => handleSelect("")}>
                                  Select a plan
                                </div>
                                {hasPackages.length > 0 && hasPackages.map((option, index) => (
                                  <div
                                    key={index}
                                    className="option"
                                    onClick={() => handleSelect(option)}
                                  >
                                    {option.duration} {option.duration === 1 ? 'Month' : 'Months'}
                                    <s> <IndianRupee size={13} />{option.actualPrice}</s> <IndianRupee size={13} />{option.discountedPrice}
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        {/*</div>*/}
                  {/*//   })*/}
                  {/*// ) : (*/}
                  {/*//   <div>Loading...</div>*/}
                  {/*// )}*/}
                </div>
                {Object.keys(selectedPlan).length > 0 ? (
                  <div className="">
                    <div className="d-flex justify-content-between pt-2">
                      <small>Sub Total</small>
                      <small>
                        <IndianRupee size={13} />{hasCart.total?.toLocaleString("en-IN", {
                          maximumFractionDigits: 2,
                        })}
                      </small>
                    </div>
                    <div className="d-flex justify-content-between pt-2">
                      <small>GST @18%</small>
                      <small>
                        <IndianRupee size={13}/>{hasCart.gst?.toLocaleString("en-IN", {
                          maximumFractionDigits: 2,
                        })}
                      </small>
                    </div>
                    <div className="d-flex justify-content-between py-2">
                      <small className="fw-bold">Total</small>
                      <small className="fw-bold">
                        <IndianRupee size={13} />{hasCart.grandTotal?.toLocaleString("en-IN", {
                          maximumFractionDigits: 2,
                        })}
                      </small>
                    </div>
                  </div>
                ) : (""
                  // <div className="text-center pb-2 rounded">
                  //   Please select plan
                  //   {/*<img src="/assets/img/empty-cart.jpg" alt="empty" />*/}
                  // </div>
                )}
                <div className="d-flex justify-content-center mt-3">
                  {/*<button className="btn btn-sm btn-success rounded-2">Continue <MoveRightIcon size={16}/>*/}
                  <div>
                    <MakePaymentProcess planData={selectedPlan} componentKey={1000} total={window.total} gst={window.calculatedGst} grandTotal={window.grandTotal}/>
                  </div>
                  {/*</button>*/}
                  <button className="btn btn-sm btn-outline-success rounded-2 ms-3"><PhoneCallIcon size={16}/> Contact
                    Us
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PlaneCompare/>
      <TradingInvestingFAQ/>
    </Layout>
  );
};

export default PowerPlus;
