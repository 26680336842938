import Layout from "../../layout/interface/Layout";
import Mobile from "../../layout/interface/guest/mobile";
import Otp from "../../layout/interface/guest/otp";
import Detail from "../../layout/interface/guest/detail";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import TradingInvestingFAQ from "../../service/trading-investing/TradingInvestingFAQ";

export default function GetStarted() {
  const section = useSelector((state) => state.auth.loginStep);
  const userDetail = useSelector((state) => state.auth.userDetail);

  useEffect(() => {
    if (section === "successfully_logged_in") {
      window.location.href = "/dashboard";
    }
  }, [section]);

  const loadSection = () => {
    if (["mobile-initial", "mobile"].includes(section)) return <Mobile />;
    else if (section === "otp") return <Otp />;
    else if (section === "details" || !userDetail.email) return <Detail />;
  };

  return (
    <Layout>
      <section  className="bg-green text-body-emphasis">
        <div className="container py-5">
          <div className="row justify-content-center align-items-center">
            <div className="col-md-6">
              <div className="text-center text-md-start">
                <p style={{ letterSpacing: "1px" }}>
                  Want to create a second source of income?
                </p>
                <h1 style={{ letterSpacing: "2px" }} className="fw-bold">
                  Your Journey of Wealth Creation Begins...
                </h1>
              </div>
            </div>
            <div className="col-md-4 offset-md-1">
              <div className="bg-white border text-center p-5 my-5 shadow rounded-3">
                <small className="text-center" style={{ letterSpacing: "1px" }}>
                  It will take just
                </small>
                <h4
                  style={{ letterSpacing: "1px" }}
                  className="fw-bold text-center"
                >
                  A Few Minutes!
                </h4>
                {loadSection("mobile")}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center py-5">
                <h1 className="fw-bold">
                  Need any help? Contact To Support Team!
                </h1>
                <p>
                  We are always there for you every time and will guide you at
                  every step. <br />
                  The team of certified advisors will assist you in achieving
                  the wealth creation goal.
                </p>
                <div className="d-flex text-success justify-content-center fw-bold">
                  <p>+91 8000 816 688</p>
                  <p className="mx-2"> | </p>
                  <p>support@equitypandit.com</p>
                </div>
                <button
                  className="btn btn-dark"
                  onClick={() => handleRedirect("/contact-us")}
                >
                  Contact Us
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-success bg-gradient bg-opacity-25 text-body-emphasis d-none">
        <div className="container py-3 text-center d-flex justify-content-center">
          <span>We Are Happy To Help You!</span>
          <a
            href="tel:+918000816688"
            className="mx-lg-4 mx-2 text-decoration-none"
          >
            <span>+918000816688</span>
          </a>
          <a
            href="mailto:support@equitypandit.com"
            className="text-decoration-none"
          >
            <span>support@equitypandit.com</span>
          </a>
        </div>
      </section>
      <section className="bg-success bg-gradient bg-opacity-10 py-5">
        <div className="container py-lg-5">
          <div className="row">
            <div className="col-lg-12">
              <div className="display-5 text-center py-3 text-black fw-bold">
                A Financial Plan That Works Perfectly
              </div>
            </div>
            <div className="col-lg-3 col-md-3">
              <div className="text-center my-lg-0 my-3">
                <img
                  src="/assets/img/circle_img(1).png"
                  alt="investors&amp;traders"
                  className="img-fluid"
                />
                <div className="text-center fw-bold text-black">
                  ARTIFICIAL INTELLIGENCE
                </div>
                <div className="text-center">Based Financial Plan</div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3">
              <div className="text-center my-lg-0 my-3">
                <img
                  src="/assets/img/circle_img(4).png"
                  alt="investors&amp;traders"
                  className="img-fluid"
                />
                <div className="text-center fw-bold text-black">
                  VALIDATED BY
                </div>
                <div className="text-center">
                  A Certified Investment Advisor
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3">
              <div className="text-center my-lg-0 my-3">
                <img
                  src="/assets/img/circle_img(2).png"
                  alt="investors&amp;traders"
                  className="img-fluid"
                />
                <div className="text-center fw-bold text-black">
                  A BALANCED PLAN
                </div>
                <div className="text-center">To Meet All Your Goals</div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3">
              <div className="text-center my-lg-0 my-3">
                <img
                  src="/assets/img/circle_img(3).png"
                  alt="investors&amp;traders"
                  className="img-fluid"
                />
                <div className="text-center fw-bold text-black">
                  UNBIASED ADVICE
                </div>
                <div className="text-center">With Full Transparency</div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className=" py-5">
        <div className="container my-5 ">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-6">
              <div className="row justify-content-center my-lg-0 my-3">
                <div className="col-12">
                  <div className="display-5 ms-lg-0 ms-3 text-black text-md-center text-lg-start fw-bold">
                    How This Financial Plan Would Help Me?
                  </div>
                  <h5 className="ms-lg-1 ms-3 mt-2 text-black text-md-center text-lg-start ">
                    EquityPandit's specially designed AI Based Financial Plan
                    would help you in following:
                  </h5>
                </div>

                <div className="col-12 col-lg-12 col-md-9">
                  <ul className="text-start ">
                    <li className="ms-1 p-2">
                      It would provide you the report of complete Risk Profile
                      Analysis.
                    </li>
                    <li className="ms-1 p-2">
                      It would suggest you how much to invest?
                    </li>
                    <li className="ms-1 p-2">
                      It would suggest you where to invest For maximum
                      profitability?
                    </li>
                    <li className="ms-1 p-2">
                      It would provide you a Balanced Plan to meet all your
                      goals.
                    </li>
                    <li className="ms-1 p-2">
                      It would provide you with a Fund Allocation Strategy.
                    </li>
                    <li className="ms-1 p-2">
                      It would help you with the Financial Security during
                      emergencies.
                    </li>
                    <li className="ms-1 p-2">
                      Complete guidance for Lifetime Financial Independence.
                    </li>
                    <li className="ms-1 p-2">
                      It would also help you find the best service for yourself.
                    </li>
                    <li className="ms-1 p-2">And in the end, it's FREE.</li>
                  </ul>
                </div>
                <div className="col-md-1"></div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="text-lg--start text-md-center">
                <img
                  src="/assets/img/workplace.png"
                  alt="investors&amp;traders"
                  className="img-fluid my-lg-0 my-3"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <TradingInvestingFAQ />
    </Layout>
  );
}
