import React from 'react';
import Layout from "../../layout/interface/Layout";
import {APP_URL} from "../../../config/constants.config";

const Welcome2 = () => {
  return (
    <Layout>
      <section className="" id="man_image_background">
        <div className="container py-lg-5">
          <div className="row pb-lg-3">
            <div className="col-lg-6 order-2 order-lg-1 d-none d-md-block">
              <h5 className="fw-bolder mt-5 text-center text-black mb-3"
                  style={{textTransform: "uppercase", letterSpacing: "3px"}}>What makes equitypandit</h5>
              <h2 className=" fw-bolder mb-3 display-5 text-center text-black">India's Leading Equity <br
                className="d-none d-md-flex d-lg-none  fw-bolder"/> Research Company? </h2>

              <h5 className="fw-bold text-center text-black mb-3" style={{letterSpacing: "2px", height: "45px"}}>
                    <span className="txt-rotate" data-period="1500" data-rotate="[
                        &quot;Pioneer Of The Research Industry&quot;,
                        &quot;15 Years Of Research Excellence&quot;,
                        &quot;Known For The Best In Stock Research&quot;,
                        &quot;National and International Awards&quot;,
                        &quot;Pioneer In Technological Innovation&quot;,
                        &quot;Excellent Reviews by The Subscribers&quot;,
                        &quot;Empower Subscribers With Training &amp; Workshops&quot;]"></span>
              </h5>
              <div className="text-center header-button mb-2 pt-2">

                <a href="/get-started">
                  <button type="button"
                          className="btn btn-sm btn-success rounded-3 border-0 py-2 px-4 text-white">Explore Now
                  </button>
                </a>
              </div>
            </div>

          </div>
        </div>
      </section>
      <section className="d-block d-lg-none d-md-none" id="man_image_responsive">

        <div className="container">
          <div className="row ">
            <div className="col-lg-6 order-2">
              <h5 className="fw-bold mt-5 text-center text-black mb-3 fs-18"
                  style={{textTransform: "uppercase", letterSpacing: "3px"}}>What makes equitypandit</h5>
              <h2 className="mb-3 display-5 fw-bold text-center text-black">India's Leading Equity <br
                className="d-none d-md-flex d-lg-none"/> Research Company? </h2>

              <h5 className="fw-bold text-center text-black mb-3" style={{letterSpacing: "2px", height: "45px"}}>
                    <span className="txt-rotate text-orange" data-period="1500" data-rotate="[
                        &quot;Pioneer Of The Research Industry&quot;,
                        &quot;15 Years Of Research Excellence&quot;,
                        &quot;Known For The Best In Stock Research&quot;,
                        &quot;National and International Awards&quot;,
                        &quot;Pioneer In Technological Innovation&quot;,
                        &quot;Excellent Reviews by The Subscribers&quot;,
                        &quot;Empower Subscribers With Training &amp; Workshops&quot;]"><span className="wrap">Pioneer In Technological Innovat</span></span>
              </h5>
              <div className="text-center header-button mb-5 pb-5 pt-2">
                <a href="/get-started">
                  <button type="button"
                          className="btn btn-sm btn-success rounded-3 border-0 py-2 px-4 text-white">Explore Now
                  </button>
                </a>

              </div>
              <div className="my-5 py-5"></div>
              <div className="my-5 py-2"></div>
            </div>
          </div>
        </div>
      </section>
      <section className="box_shadow_bottom mt-3">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-1"></div>
            <div className="col-lg-2 col-md-2 col-5">
              <div className="ver_center">
                <img className="img-fluid ms-lg-5 me-3"
                     src={`${APP_URL}/ep-wealth/business-standard-logo.png`}
                     alt="business_standard"/>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 col-5">
              <div className="ver_center">
                <img className="img-fluid mx-lg-5 ps-lg-4"
                     src={`${APP_URL}/ep-wealth/daily_hunt.png`} alt="dailyhunt"/>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 col-5">
              <div className="ver_center">
                <img className="img-fluid mx-lg-5" src={`${APP_URL}/ep-wealth/bloomberg.png`}
                     alt="bloomberg"/>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 col-5">
              <div className="ver_center">
                <img className="img-fluid mx-lg-5" src={`${APP_URL}/ep-wealth/the_week.png`}
                     alt="the_week"/>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 col-5">
              <div className="ver_center">
                <img className="img-fluid mx-lg-5" src={`${APP_URL}/ep-wealth/yahoo.png`}
                     alt="yahoo"/>
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>
      </section>
      <section className=" mb-1 mx-lg-5">
        <div className="container pb-lg-5 pt-lg-5">
          <div className="row justify-content-center pt-lg-4">
            <div className="col-lg-6 col-md-12">
              <div className="text-md-center text-lg-start">
                <img src={`${APP_URL}/assets/img/investors&traders.png`}
                     alt="investors&amp;traders" className="img-fluid"/>
              </div>

            </div>
            <div className="col-lg-6 col-md-12 ">
              <div
                className="display-5 text-center fw-bold text-md-center text-lg-start mt-4 text-black ms-lg-5 ps-lg-3"
              >
                Investors &amp; Traders Loves Equitypandit.
              </div>
              <h1
                className="count_size text-center fw-bolder  text-md-center text-lg-start me-lg-3 text-orange ms-lg-5 ps-lg-3"
              >485,000+</h1>
              <h5 className="gray_text text-center  text-md-center text-lg-start me-lg-5 pe-lg-5 ms-lg-5 ps-lg-3"
              >Investors &amp; Traders Use Equitypandit</h5>
            </div>
            {/*<div className="col-12">*/}
            {/*  <div className="text-center mt-3">*/}
            {/*    <button id="traders" type="button" className="bg-white" style={{"border": "none"}}>*/}
            {/*      <i className="fa fa-chevron-down fa-2x text-black"></i>*/}
            {/*    </button>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>
      </section>
      <section id="b_traders" className="mt-3 mb-lg-2 pb-2">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="display-5 fw-bold pt-4 text-center text-black mt-5 mb-lg-0 mb-4">
                EquityPandit Is A Contributor To The World’s Biggest Financial Portals
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className="m-2 p-2 mt-lg-5 mb-lg-5 order-1 order-lg-3 my-box-shadow wow fadeIn animated"
                   style={{visibility: "visible"}}>
                <img className="img-fluid" src={`${APP_URL}/assets/img/investing.png`} alt=""/>
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className="m-2 p-2 mt-lg-5 mb-lg-5 order-3 order-lg-1 my-box-shadow wow fadeIn animated"
                   style={{visibility: "visible"}}>
                <img className="img-fluid" src={`${APP_URL}/assets/img/Factset.png`} alt=""/>
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className="m-2 p-2 mt-lg-5 mb-lg-5 order-2 order-lg-2 my-box-shadow wow fadeIn animated"
                   style={{visibility: "visible"}}>
                <img className="img-fluid" src={`${APP_URL}/assets/img/ThomsonReuters.png`}
                     alt=""/>
              </div>
            </div>
            {/*<div className="col-12">*/}
            {/*  <div className="text-center mt-3">*/}
            {/*    <button id="contributor" type="button" className="bg-white" style="border: none;">*/}
            {/*      <i className="fa fa-chevron-down fa-2x text-black"></i>*/}
            {/*    </button>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>
      </section>
      <section className=" mb-lg-5 pb-5 d-none d-md-none d-lg-block" id="rectangle_img">
        <div className="container">
          <div className="row pt-lg-5 pb-lg-5" id="b_magazines">
            <div className="col-lg-6 col-md-12">
              <div className="feature-thumb pt-lg-5 ps-0">
                <img id="slide1" className="img-fluid rotate_3d"
                     src="https://equitypandit.co.in/assets/img/homepage/g-rank(1).png" alt=""/>
                <img id="slide2" className="img-fluid rotate_3d"
                     src="https://equitypandit.co.in/assets/img/homepage/g-rank(2).png" alt=""/>
                <img id="slide3" className="img-fluid rotate_3d"
                     src="https://equitypandit.co.in/assets/img/homepage/g-rank(3).png" alt=""/>
                <img id="slide4" className="img-fluid rotate_3d"
                     src="https://equitypandit.co.in/assets/img/homepage/g-rank(4).png" alt=""/>
                <img id="slide5" className="img-fluid rotate_3d"
                     src="https://equitypandit.co.in/assets/img/homepage/g-rank(5).png" alt=""/>
                <img id="slide6" className="img-fluid rotate_3d"
                     src="https://equitypandit.co.in/assets/img/homepage/g-rank(6).png" alt=""/>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="pt-lg-5 pb-lg-5">
                <div className="mobile-text-center">
                  <div className="display-5 fw-bold text-black ms-5 ps-1 mt-2">
                    Google Speaks Out Loud For Equitypandit
                  </div>
                  <div id="notification"></div>
                  <p className="mb-3 mt-2 pb-2 ms-5 ps-1 text-gray">EquityPandit Trending on the Top for Every Search
                    Related To Indian Stock Market</p>
                  <div className="ms-2">
                    <a href="/services/trading-investing"
                       className="btn btn-sm btn-success rounded-3 border-0 py-2 px-4 text-white ms-lg-5 mt-1">Get
                      Started</a>
                  </div>
                </div>
              </div>
            </div>
            {/*<div className="col-12">*/}
            {/*  <div className="text-center mb-5">*/}
            {/*    <button id="google" type="button" className="bg-white" style="border: none;">*/}
            {/*      <i className="fa fa-chevron-down fa-2x text-black"></i>*/}
            {/*    </button>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>
      </section>
      <section className="px-2 pb-5 mt-5 d-block d-lg-none" id="rectangle_img_mobile">
        <div className="container">
          <div className="row" id="b_magazines2">
            <div className="col-lg-6 col-md-12 ps-0">
              <div className="feature-thumb pt-5 ps-5">
                <img id="slide1" className="img-fluid rotate_3d"
                     src="https://equitypandit.co.in/assets/img/homepage/g-rank(1).png" alt=""/>
                <img id="slide2" className="img-fluid rotate_3d"
                     src="https://equitypandit.co.in/assets/img/homepage/g-rank(2).png" alt=""/>
                <img id="slide3" className="img-fluid rotate_3d"
                     src="https://equitypandit.co.in/assets/img/homepage/g-rank(3).png" alt=""/>
                <img id="slide4" className="img-fluid rotate_3d"
                     src="https://equitypandit.co.in/assets/img/homepage/g-rank(4).png" alt=""/>
                <img id="slide5" className="img-fluid rotate_3d"
                     src="https://equitypandit.co.in/assets/img/homepage/g-rank(5).png" alt=""/>
                <img id="slide6" className="img-fluid rotate_3d"
                     src="https://equitypandit.co.in/assets/img/homepage/g-rank(6).png" alt=""/>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="mobile-text-center">
                <div className="display-5 fw-bold text-black text-md-center text-lg-start mt-5 pt-lg-5">
                  Google Speaks Out Loud For Equitypandit
                </div>
                <p className="mb-3 mt-2 pb-2 text-md-center text-lg-start text-gray">EquityPandit Trending on the Top
                  for Every Search Related To Indian Stock Market</p>
                {/*<div className="text-md-center text-lg-start">*/}
                {/*  <a href="https://equitypandit.co.in/packages"*/}
                {/*     className="btn btn-theme rounded_curve_border border-0 py-2 px-4 text-white mt-1">Get Started</a>*/}
                {/*</div>*/}
              </div>
            </div>
            {/*<div className="col-12">*/}
            {/*  <div className="text-center mt-5 mb-5 d-block d-lg-none">*/}
            {/*    <button id="google2" type="button" className="bg-white" style="border: none;">*/}
            {/*      <i className="fa fa-chevron-down fa-2x text-black"></i>*/}
            {/*    </button>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>
      </section>
      <section className="my-lg-5 pb-3" id="b_pioneer">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="display-5 fw-bold text-center text-black mt-4 mb-3">National &amp; International Awards</div>
            </div>
            <div className="row justify-content-center fail ps-4 pe-0">
              <div className="col-lg-10 col-12">
                <div className="row">
                  <div className="col-md-4 col-6">
                    <div className="p-lg-1 mb-lg-4 mt-lg-4">
                      <img className="awd_cls" src="/assets/img/awds1.jpg"
                           alt="economic_times"/>
                    </div>
                  </div>
                  <div className="col-md-4 col-6">
                    <div className="p-lg-1 mb-lg-4 mt-lg-4">
                      <img className="awd_cls" src="/assets/img/awds2.jpg"
                           alt="research"/>
                    </div>
                  </div>
                  <div className="col-md-4 col-6">
                    <div className="p-lg-1 mb-lg-4 mt-lg-4">
                      <img className="awd_cls" src="/assets/img/awds3.jpg"
                           alt="et_now"/>
                    </div>
                  </div>
                  <div className="col-md-4 col-6">
                    <div className="p-lg-1 mb-lg-4">
                      <img className="awd_cls" src="/assets/img/awds4.jpg"
                           alt="finext"/>
                    </div>
                  </div>
                  <div className="col-md-4 col-6">
                    <div className="p-lg-1 mb-lg-4">
                      <img className="awd_cls" src="/assets/img/awds5.jpg"
                           alt="financial"/>
                    </div>
                  </div>
                  <div className="col-md-4 col-6">
                    <div className="p-lg-1 mb-lg-4">
                      <img className="awd_cls" src="/assets/img/awds6.jpg"
                           alt="growing_research"/>
                    </div>
                  </div>
                  <div className="col-md-4 col-6">
                    <div className="p-lg-1 mb-lg-5">
                      <img className="awd_cls" src="/assets/img/awds7.jpg"
                           alt="enterprice_world"/>
                    </div>
                  </div>
                  <div className="col-md-4 col-6">
                    <div className="p-lg-1 mb-lg-5">
                      <img className="awd_cls" src="/assets/img/awds8.jpg"
                           alt="cmo_asia"/>
                    </div>
                  </div>
                  <div className="col-md-4 col-6">
                    <div className="p-lg-1 mb-5">
                      <img className="awd_cls" src="/assets/img/awds9.jpg"
                           alt="msme_award"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ----------App Section----------- */}
      <section className="py-5">
        <div className="container my-5 bg-green rounded-3">
          <div className="py-5 p-md-5 my-5">
            <div className="row g-3 align-items-center">
              <div className="col-md-6 text-center text-md-start">
                {/* <h1 className="fw-bold">Download The App</h1> */}
                <h1 className="mb-1 fw-bold">
                  Get Real-Time Recommendations at Your Finger Tips
                </h1>
                <p>
                  Our Subscribers gets all recommendations in real-time. Join
                  India's Most Popular Investment Advisory Now!
                </p>
                <div className="d-flex">
                  <button className="btn">
                    <img
                      className="rounded-3 img-fluid"
                      src={`${APP_URL}/assets/img/android-app.png`}
                      alt="support_image"
                    />
                  </button>
                  <button className="btn mt-2">
                    <img
                      className="rounded-3 img-fluid"
                      src={`${APP_URL}/assets/img/ios-app.png`}
                      alt="support_image"
                    />
                  </button>
                </div>
              </div>
              <div className="col-md-6 text-center">
                <div className="d-flex">
                  <div className="">
                    <img
                      src={`${APP_URL}/assets/img/mobile-app4.png`}
                      className="img-fluid"
                      alt="phone_img"
                    />
                  </div>
                  <div className="">
                    <img
                      src={`${APP_URL}/assets/img/mobile-app5.png`}
                      className="img-fluid"
                      alt="phone_img"
                    />
                  </div>
                  <div className="">
                    <img
                      src={`${APP_URL}/assets/img/mobile-app6.png`}
                      className="img-fluid"
                      alt="phone_img"
                    />
                  </div>
                </div>
              </div>
              {/* <div className="col-md-6 text-center d-block d-lg-none d-md-none mt-3">
                <div className="d-flex">
                  <div className="">
                    <img

                      src={`${APP_URL}/assets/img/mobile-app4.png`}
                      className="img-fluid"
                      alt="phone_img"
                    />
                  </div>
                  <div className="">
                    <img

                      src={`${APP_URL}/assets/img/mobile-app5.png`}
                      className="img-fluid"
                      alt="phone_img"
                    />
                  </div>
                  <div className="">
                    <img

                      src={`${APP_URL}/assets/img/mobile-app6.png`}
                      className="img-fluid"
                      alt="phone_img"
                    />
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      {/* ----------App Section----------- */}
    </Layout>
  );
};

export default Welcome2;
