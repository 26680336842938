import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
// import {refreshToken} from "./redux/auth/auth";
import {useEffect} from "react";
import {refreshToken} from "./redux/auth/auth";

const AxiosInterceptor = () => {
  const dispatch = useDispatch();
  const _refreshToken = useSelector((state) => state?.auth?.reFreshToken);
  const _accessToken = useSelector((state) => state?.auth?.accessToken);

  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      response => response,
      async error => {
        const originalRequest = error.config;
        if (error.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          try {
            const resultAction = await dispatch(refreshToken(_refreshToken));
            if (refreshToken.fulfilled.match(resultAction)) {
              const newAccessToken = ''; //resultAction.payload.data.accessToken;

              axios.defaults.headers.common["Authorization"] = `Bearer ${newAccessToken}`;

              // Update the original request with the new token
              originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;

              // Retry the original request with the new token
              return axios(originalRequest);
            } else {
              localStorage.removeItem("persist:root");
            }
          } catch (refreshError) {
            // Handle refresh token errors, e.g., logout the user or show an error message
            return Promise.reject(refreshError);
          }
        }
        return Promise.reject(error);
      }
    );
    return () => {
      axios.interceptors.response.eject(interceptor);
    };

  }, [dispatch, _refreshToken, _accessToken]);

  return (
    <></>
  );
};

export default AxiosInterceptor;
