import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useEffect, useRef } from "react";
// import {reduxLogin} from "@/redux/module/auth";
import { MessageSquareText } from "lucide-react";
// import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { reduxLogin } from "../../../../redux/auth/auth";

export default function Mobile({ setLoginForm }) {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const mobileRef = useRef(null);
  const source =
    useSelector((state) => state.auth.userDetail.source) || "ep_co_in";
  const mobile = useSelector((state) => state.auth.mobile);
  const loginStep = useSelector((state) => state.auth.loginStep);
  const authState = useSelector((state) => state.auth.authState);

  useEffect(() => {
    if (mobileRef.current) {
      mobileRef.current.focus();
    }
  }, [authState, loginStep, mobile, navigation]);

  const validationSchema = Yup.object({
    mobile: Yup.string()
      .required("Mobile is required")
      .min(10, "Mobile must be 10 digits.")
      .max(10, "Mobile must be 10 digits."),
  });

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        countryCode: "+91",
        otpVia: "text",
        source,
        mobile: "",
        loginVia: "clientWeb",
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        dispatch(reduxLogin(values));
        // setLoginForm('otp')
      }}
      enableReinitialize
    >
      {({ isSubmitting, touched, errors, values, setFieldValue }) => (
        <Form>
          <div className="form-group col-md-10 offset-lg-1">
            <div className="mb-2 mt-3">
              <small>Enter Your Mobile Number</small>
            </div>
          </div>
          <div className="form-group">
            <Field
              className={`form-control text-center ${
                touched.mobile && errors.mobile
                  ? "is-invalid"
                  : "border-dark-subtle"
              }`}
              type="text"
              placeholder="XXXX XXX XXX"
              name="mobile"
              value={values.mobile}
              innerRef={mobileRef}
              onChange={(e) => {
                setFieldValue("mobile", e.target.value.slice(0, 10));
              }}
            />
            <ErrorMessage
              name="mobile"
              component="div"
              className="invalid-feedback"
            />
          </div>
          <small className="mt-3 mb-2 d-block">GET OTP ON</small>
          <button
            type="submit"
            className="btn btn-dark"
            disabled={isSubmitting}
            onChange={(e) =>
              setHasLogin({
                ...hasLogin,
                otpVia: "text",
              })
            }
          >
            <i className="bi bi-chat-left-text-fill"></i>
            <MessageSquareText size={15} /> SMS
          </button>
          <button
            type="submit"
            className="btn btn-outline-dark ms-2"
            disabled={isSubmitting}
            onChange={(e) =>
              setHasLogin({
                ...hasLogin,
                otpVia: "whatsapp",
              })
            }
          >
            <FontAwesomeIcon icon={faWhatsapp}></FontAwesomeIcon> WhatsApp
          </button>
          <span className="d-block mt-2">
            {isSubmitting && "Submitting..."}
          </span>
        </Form>
      )}
    </Formik>
  );
}
