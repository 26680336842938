import React, {useEffect, useRef} from "react";

export default function TradingInvestingFAQ() {
  const openFirstFAQ = useRef(null);

  useEffect(() => {
    if (openFirstFAQ.current) {
      openFirstFAQ.current.click();
    }
  }, []);

  return (
    <section
      style={{backgroundImage: 'url("/assets/img/bg.png")'}}>
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-md-10">
            <div className="text-center mb-5">
              <h1 className="fw-bold">Frequently Asked Questions</h1>
              <p>
                Need a help with something? Here are our most frequently asked
                questions.
              </p>
            </div>
            <ul className="list-unstyled bg-white p-4 rounded-3 shadow accordion accordion-flush"
                id="accordionFlushExample">
              <li
                className="py-3 fs-18 border-bottom"
                style={{cursor: "pointer"}}
              >
                <div
                  id="flush-headingOne"
                  data-bs-target="#flush-collapseOne"
                  aria-controls="flush-collapseOne"
                  data-bs-toggle="collapse"
                  className="fw-bold text-success question"
                  aria-expanded="true"
                  ref={openFirstFAQ}
                >
                  Why is the Risk Profile Analysis so Important?
                </div>
                <div
                  id="flush-collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <p className="fs-18 py-3 mb-0">
                    Risk Profile Analysis is the most important part of
                    Financial Planning. Every Individual is unique and hence
                    his willingness, his financial capability, his
                    knowledge,his requirements and his goals are different
                    then any other individual. Desired results can be earned
                    from your investments only if you invest as per your
                    requirements. Investment Strategy is also different for
                    different individuals. It has been noted that investors
                    who trade or invest in accordance to their risk profile
                    mostly generate superior returns.
                  </p>
                </div>
              </li>

              <li
                className="py-3 fs-18 border-bottom"
                style={{cursor: "pointer"}}
              >
                <div
                  id="flush-headingTwo"
                  data-bs-target="#flush-collapseTwo"
                  aria-controls="flush-collapseTwo"
                  data-bs-toggle="collapse"
                  aria-expanded="true"
                  className="fw-bold text-success question collapsed"
                >
                  Does equitypandit generate a Financial Plan for free?
                </div>
                <div
                  id="flush-collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingTwo"
                  data-bs-parent="#accordionFlushExample"
                >
                  <p className="fs-18 py-3 mb-0">
                    Yes, we {"don't"} charge for the Financial Plan. It’s the
                    most Powerful Artificial Intelligence Based Financial
                    Plan, yet it is absolutely free.
                  </p>
                </div>
              </li>

              <li
                className="py-3 fs-18 border-bottom"
                style={{cursor: "pointer"}}
              >
                <div
                  id="flush-headingThree"
                  data-bs-target="#flush-collapseThree"
                  aria-controls="flush-collapseThree"
                  data-bs-toggle="collapse"
                  aria-expanded="true"
                  className="fw-bold text-success question collapsed"
                >
                  Do you also provide paid premium stock research advice?
                </div>
                <div
                  id="flush-collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingThree"
                  data-bs-parent="#accordionFlushExample"
                >
                  <p className="fs-18 py-3 mb-0">
                    Yes, we do provide Premium research advice and you would
                    be glad to know that you are at the right place as
                    EquityPandit is {"India's"} Leading Equity Research
                    company. If you wish to avail the paid services, our
                    Certified analysts and advisors would provide you
                    investment advice and assist you at every step until the
                    booking of that advice.
                  </p>
                </div>
              </li>

              <li
                className="py-3 fs-18 border-bottom"
                style={{cursor: "pointer"}}
              >
                <div
                  id="flush-headingFour"
                  data-bs-target="#flush-collapseFour"
                  aria-controls="flush-collapseFour"
                  data-bs-toggle="collapse"
                  aria-expanded="true"
                  className="fw-bold text-success question collapsed"
                >
                  How can I choose the best package for myself?
                </div>
                <div
                  id="flush-collapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingFour"
                  data-bs-parent="#accordionFlushExample"
                >
                  <p className="fs-18 py-3 mb-0">
                    You {"don't"} have to worry about it. In your financial
                    plan,
                    {"EquityPandit's"} Artificial Intelligence based bots
                    would also suggest you the best package based on your Risk
                    Profile, Financial capability, willingness and experience.
                    We assure you that these services would indeed be the most
                    cost effective and the best service for you that can be
                    offered by EquityPandit looking at your profile.
                  </p>
                </div>
              </li>

              <li
                className="py-3 fs-18 border-bottom"
                style={{cursor: "pointer"}}
              >
                <div
                  id="flush-headingFive"
                  data-bs-target="#flush-collapseFive"
                  aria-controls="flush-collapseFive"
                  data-bs-toggle="collapse"
                  aria-expanded="true"
                  className="fw-bold text-success question collapsed"
                >
                  Do EquityPandit provide guaranteed returns?
                </div>
                <div
                  id="flush-collapseFive"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingFive"
                  data-bs-parent="#accordionFlushExample"
                >
                  <p className="fs-18 py-3 mb-0">
                    No we {"don't"}, neither EquityPandit nor anyone else can
                    provide you any guarantee of returns while trading or
                    investments in the Stock Market. Trading and Investment in
                    the Stock Market is subject to market risk and other
                    risks. But as EquityPandit Research Team consists of few
                    of the best and most experienced Analysts and Advisors in
                    India, we assure you that we put in our best efforts and
                    strive hard to provide you the best research advice.
                  </p>
                </div>
              </li>

              <li className="py-3 fs-18" style={{cursor: "pointer"}}>
                <div
                  id="flush-headingSix"
                  data-bs-target="#flush-collapseSix"
                  aria-controls="flush-collapseSix"
                  data-bs-toggle="collapse"
                  aria-expanded="true"
                  className="fw-bold text-success question collapsed"
                >
                  Can you execute the trade or investment on my behalf?
                </div>

                <div
                  id="flush-collapseSix"
                  className="accordion-collapse collapse"
                  aria-labelledby="flush-headingSix"
                  data-bs-parent="#accordionFlushExample"
                >
                  <p className="fs-18 py-3 mb-0">
                    We are sorry to say that neither EquityPandit nor its
                    employees executes any trade on your behalf. We also
                    suggest you not to share login credentials of your
                    demat/trading/bank accounts with anyone.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
