import React from "react";
import "./plan-compare.css";

export default function PlaneCompare() {
  return (
    <section className="bg-success bg-opacity-10 shadow">
      <div className="container py-5">
        <div className="row justify-content-center text-center">
          <div className="text-center pb-3">
            <h1 className="fw-bold text-capitalize text-black">choose your style for wealth creation</h1>
            <h5 className="fw-normal">
              Put your money to work with our research, that suits your style
            </h5>
          </div>
          <div className="col-md-10 m-lg-4 shadow bg-white p-0 m-0 rounded-3">
            <div className="table-responsive">
              <table className="table mb-0 text-md-nowrap fs-14 w-100 text-center">
                <tbody>
                <tr>
                  <td className="sticky-col first-col bg-white"></td>
                  <td className="bg-success text-white">Intelligent Investor</td>

                  <td className="bg-success text-white">Passionate Trader</td>
                  <td style={{background: "#DFF64D"}}>Power Plus</td>
                </tr>
                <tr>
                  <td className="sticky-col first-col bg-white"><b>Investment Range</b></td>
                  <td className="bg-light-pc">3 - 20 Lacs</td>
                  <td className="bg-light-pc">3 - 20 Lacs</td>
                  <td className="">5 - 50 Lacs</td>
                </tr>
                <tr>
                  <td className="sticky-col first-col bg-white"><b>Segment</b></td>
                  <td className="bg-light-pc ">Positional</td>
                  <td className="bg-light-pc">Intraday / Swing</td>
                  <td className="">Intraday / Swing / Positional</td>
                </tr>
                <tr>
                  <td className="sticky-col first-col bg-white"><b>Objective</b></td>
                  <td className="bg-light-pc ">Wealth Creation</td>
                  <td className="bg-light-pc">Income Generation</td>
                  <td className="">Wealth + Income</td>
                </tr>
                <tr>
                  <td className="sticky-col first-col bg-white"><b>Investor/Trader Profile</b>
                  </td>
                  <td className="bg-light-pc"><span>Any</span></td>
                  <td className="bg-light-pc">Aggressive</td>
                  <td className="">Aggressive</td>
                </tr>
                <tr className="d-none">
                  <td className="sticky-col first-col bg-white"><b>Half Yearly Fees</b></td>
                  <td className="bg-light">
                    <h4>Rs.55,000/-</h4>
                  </td>
                  <td className="bg-light">
                    <h4>Rs.55,000/-</h4>
                  </td>
                  <td className="">
                    <h4>Rs.59,999/-</h4>
                  </td>
                </tr>
                <tr>
                  <td className="sticky-col first-col bg-white"><b>Banknifty Futures and
                    Options</b></td>
                  <td className="bg-light-pc "><i className="fa fa-times text-danger" aria-hidden="true"></i></td>
                  <td className="bg-light-pc"><i className="fa fa-check text-success"></i></td>
                  <td className=""><i className="fa fa-check text-success"></i></td>
                </tr>
                <tr>
                  <td className="sticky-col first-col bg-white"><b>Nifty Futures and Options</b>
                  </td>
                  <td className="bg-light-pc "><i className="fa fa-times text-danger" aria-hidden="true"></i></td>
                  <td className="bg-light-pc"><i className="fa fa-check text-success"></i></td>
                  <td className=""><i className="fa fa-check text-success"></i></td>
                </tr>
                <tr>
                  <td className="sticky-col first-col bg-white"><b>Stock Futures</b></td>
                  <td className="bg-light-pc "><i className="fa fa-times text-danger" aria-hidden="true"></i></td>
                  <td className="bg-light-pc"><i className="fa fa-check text-success"></i></td>
                  <td className=""><i className="fa fa-check text-success"></i></td>
                </tr>
                <tr>
                  <td className="sticky-col first-col bg-white"><b>F&amp;O Strategies</b></td>
                  <td className="bg-light-pc "><i className="fa fa-times text-danger" aria-hidden="true"></i></td>
                  <td className="bg-light-pc"><i className="fa fa-check text-success"></i></td>
                  <td className=""><i className="fa fa-check text-success"></i></td>
                </tr>
                <tr>
                  <td className="sticky-col first-col bg-white"><b>Stock Analysis Traning</b>
                  </td>
                  <td className="bg-light-pc "><i className="fa fa-check text-success"></i></td>
                  <td className="bg-light-pc"><i className="fa fa-check text-success"></i></td>
                  <td className=""><i className="fa fa-check text-success"></i></td>
                </tr>
                <tr>
                  <td className="sticky-col first-col bg-white"><b>Priority Support</b></td>
                  <td className="bg-light-pc "><i className="fa fa-check text-success"></i></td>
                  <td className="bg-light-pc"><i className="fa fa-check text-success"></i></td>
                  <td className=""><i className="fa fa-check text-success"></i></td>
                </tr>
                <tr>
                  <td className="sticky-col first-col bg-white"><b>Short Term Investment</b></td>
                  <td className="bg-light-pc "><i className="fa fa-check text-success"></i></td>
                  <td className="bg-light-pc"><i className="fa fa-times text-danger" aria-hidden="true"></i></td>
                  <td className=""><i className="fa fa-check text-success"></i></td>
                </tr>
                <tr>
                  <td className="sticky-col first-col bg-white"><b>Long Term Investment</b></td>
                  <td className="bg-light-pc "><i className="fa fa-check text-success"></i></td>
                  <td className="bg-light-pc"><i className="fa fa-times text-danger" aria-hidden="true"></i></td>
                  <td className=""><i className="fa fa-check text-success"></i></td>
                </tr>
                <tr>
                  <td className="sticky-col first-col bg-white"><b>Value Investing</b></td>
                  <td className="bg-light-pc "><i className="fa fa-check text-success"></i></td>
                  <td className="bg-light-pc"><i className="fa fa-times text-danger" aria-hidden="true"></i></td>
                  <td className=""><i className="fa fa-check text-success"></i></td>
                </tr>
                <tr>
                  <td className="sticky-col first-col bg-white"><b>Multibagger Investment</b>
                  </td>
                  <td className="bg-light-pc "><i className="fa fa-check text-success"></i></td>
                  <td className="bg-light-pc"><i className="fa fa-times text-danger" aria-hidden="true"></i></td>
                  <td className=""><i className="fa fa-check text-success"></i></td>
                </tr>
                <tr>
                  <td className="sticky-col first-col bg-white"><b>Research Reports</b></td>
                  <td className="bg-light-pc "><i className="fa fa-check text-success"></i></td>
                  <td className="bg-light-pc"><i className="fa fa-times text-danger" aria-hidden="true"></i></td>
                  <td className=""><i className="fa fa-check text-success"></i></td>
                </tr>
                <tr>
                  <td className="sticky-col first-col bg-white"><b>Portfolio Analysis</b></td>
                  <td className="bg-light-pc "><i className="fa fa-check text-success"></i></td>
                  <td className="bg-light-pc"><i className="fa fa-times text-danger" aria-hidden="true"></i></td>
                  <td className=""><i className="fa fa-check text-success"></i></td>
                </tr>
                <tr>
                  <td className="sticky-col first-col bg-white"><b>Financial Planning</b></td>
                  <td className="bg-light-pc "><i className="fa fa-check text-success"></i></td>
                  <td className="bg-light-pc"><i className="fa fa-times text-danger" aria-hidden="true"></i></td>
                  <td className=""><i className="fa fa-check text-success"></i></td>
                </tr>
                <tr>
                  <td className="sticky-col first-col bg-white"><b>Mutual Fund / SIP Guidence</b>
                  </td>
                  <td className="bg-light-pc "><i className="fa fa-check text-success"></i></td>
                  <td className="bg-light-pc"><i className="fa fa-times text-danger" aria-hidden="true"></i></td>
                  <td className=""><i className="fa fa-check text-success"></i></td>
                </tr>
                <tr>
                  <td className="sticky-col first-col bg-white"><b>Dedicated Relationship
                    Manager</b></td>
                  <td className="bg-light-pc "><i className="fa fa-times text-danger" aria-hidden="true"></i></td>
                  <td className="bg-light-pc"><i className="fa fa-times text-danger" aria-hidden="true"></i></td>
                  <td className=""><i className="fa fa-check text-success"></i></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
