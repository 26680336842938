import Footer from "./Footer";
import Header from "./Header";
import TopBanner from "./TopBanner";

const Layout = ({children}) => {
  return (
    <>
      <Header/>
      {
        [
          "/unicorn/portfolio-analysis"
        ].includes(window.location.pathname) ?
          <TopBanner/>
          : ""
      }

      {children}
      <Footer/>
    </>
  );
};

export default Layout;
