import React, {useEffect, useState} from "react";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {getOpenTicket, getTickets, submitSupport} from "../service/support.service";
import moment from "moment";
import {useDispatch} from "react-redux";
import Layout from "../../layout/interface/Layout";

export default function Support() {
  const [successMessage, setSuccessMessage] = useState();
  const [failedMessage, setFailedMessage] = useState();
  const [currentTab, setCurrentTab] = useState(1);
  const [hasTickets, setHasTickets] = useState([]);
  const [isTicketOpen, setIsTicketOpen] = useState(false);

  const dispatch = useDispatch();
  const fetchData = () =>{
    getTickets()
      .then(response => {
        setHasTickets(response.data.data.dataList);
      })

    getOpenTicket()
      .then(response => {
        setIsTicketOpen(response.data.data.dataList.length > 0)
      })
  }
  useEffect(() => {
    fetchData();
  }, [dispatch]);

  const validationSchema = Yup.object({
    category: Yup.string()
      .required('Category is required.'),
    msg: Yup.string()
      .required('Message is required.')
      .max(250, 'Too Long!')
  });

  return (
    <Layout>
      <section className="py-lg-5 bg-success-subtle">
        <div className="container py-lg-5">
          <div className="row">
            <div className="col-lg-6 py-lg-5">
              <img src="/assets/img/support.png" alt="support_image" width={500} height={250}/>
              <div className="row justify-content-center">
                <div className="col-7">
                  <h2 className="bg-black text-white rounded-3 px-3 py-2 text-center">EquityPandit</h2>
                </div>
                <div className="col-6">
                  <div className="text-success text-center fs-5">Support Desk</div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-5">
                  <div className="text-lg-start text-center text-black mt-2">Call Directly:</div>
                  <a href="tel:+91800-081-6688">
                    <div className="text-lg-start text-center fw-bold fs-18 text-black">
                      <i className="fa fa-phone-alt" aria-hidden="true"></i>+91 800-081-6688
                    </div>
                  </a>
                </div>
                <div className="col-md-7">
                  <div className="text-lg-start text-center ms-lg-4 text-black mt-2">Email:</div>
                  <a href="mailto:support@equitypandit.com">
                    <div className="text-lg-start text-center fw-bold fs-18 ms-lg-4 text-black">
                      <i className="fa fa-envelope" aria-hidden="true"></i>support@equitypandit.com
                    </div>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <a className={`nav-link ${currentTab === 1 && 'active'}`} aria-current="page"
                     style={{cursor: "pointer"}}
                     onClick={() => setCurrentTab(1) }>New Request</a>
                </li>
                <li className="nav-item">
                  <a className={`nav-link ${currentTab === 2 && 'active'}`} aria-current="page"
                     style={{cursor: "pointer"}}
                     onClick={() => {
                       setCurrentTab(2)
                       fetchData();
                       setSuccessMessage("");
                     }}>History</a>
                </li>
              </ul>


              {
                currentTab === 1 ?
                  (!isTicketOpen ?
                    <div className="pt-4 pb-4 mb-lg-0 mb-5 px-4 box_shadow rounded-bottom-3 bg-white border-bottom">
                      <h2 className="text-center fw-bold text-black">Send Your Message</h2>
                      <hr/>
                      <Formik
                        initialValues={{
                          reqType: "main",
                          category: "",
                          msg: ""
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(values, {setSubmitting, resetForm}) => {
                          setSubmitting(false);
                          resetForm();
                          submitSupport(values)
                            .then(res => {
                              setSuccessMessage(res.data.message);
                              fetchData();
                            })
                            .catch(e => {
                              setFailedMessage(e.response.data.message)
                            })
                        }}
                        enableReinitialize
                      >
                        {({isSubmitting, touched, errors, values}) => (
                          <Form className="m-lg-4">
                            <div className="form-group col-md-10 offset-lg-1">

                              <div className="row">
                                <div className="col-lg-6 form-group mb-3 d-none">
                                  <input name="name"
                                         className="form-control border-dark-subtle rounded-3 py-2 px-3"
                                         placeholder="Name*" type="text" required=""/>
                                </div>
                                <div className="col-lg-6 form-group mb-3 d-none">
                                  <input name="email"
                                         className="form-control border-dark-subtle rounded-3 py-2 px-3"
                                         placeholder="Email*" type="email" required=""/>
                                  <span className="text-danger error_email"></span>
                                </div>
                                <div className="col-lg-6 form-group mb-3 d-none">
                                  <input id="MobileNo" name="mobile_number"
                                         className="form-control border-dark-subtle rounded-3 py-2 px-3"
                                         placeholder="Mobile*"
                                         type="text" required=""/>
                                </div>

                                {successMessage &&
                                  <div className="text-center mb-2 text-success">
                                    <small><b>{successMessage}</b></small>
                                  </div>}

                                {failedMessage &&
                                  <div className="text-center mb-2 text-danger">
                                    <small><b>{failedMessage}</b></small>
                                  </div>}

                                <div className="form-group mb-3">
                                  <Field
                                    as="select"
                                    name="category"
                                    className={`form-control rounded-3 py-2 px-3 show-tick ${touched.category && errors.category ? 'is-invalid' : 'border-dark-subtle'}`}
                                    value={values.category}
                                  >
                                    <option value="">Please select an option</option>
                                    <option value="billing">Billing</option>
                                    <option value="subscription">Subscription</option>
                                    <option value="sales">Sales</option>
                                    <option value="support">Support</option>
                                    <option value="other">Other</option>
                                  </Field>
                                  <ErrorMessage name="category" component="div"
                                                className="invalid-feedback"/>
                                </div>
                                <div className="form-group mb-3">
                                  <Field
                                    as="textarea"
                                    name="msg"
                                    rows="3"

                                    placeholder="Enter Your Message*"
                                    className={`form-control rounded-3 py-2 px-3 ${touched.msg && errors.msg ? 'is-invalid' : 'border-dark-subtle'}`}
                                    value={values.msg}
                                  >
                                  </Field>
                                  <ErrorMessage name="msg" component="div"
                                                className="invalid-feedback"/>
                                </div>

                                <div className="text-center">
                                  <button type="submit"
                                          disabled={isSubmitting}
                                          className="btn text-white bg-success rounded-pill py-2 px-4 border-0">
                                    Send Message
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div> :
                    <div className="rounded-bottom-3 bg-white border-bottom p-3">
                    <div className="text-center bg-secondary-subtle rounded my-3 py-2">
                      <span>You already have a open ticket.<br/>Please wait for some time our executive will contact you soon or Please contact our support team.</span>
                    </div></div>) :
                  <div className="rounded-bottom-3 bg-white border-bottom p-3">
                    {
                      hasTickets.map((item, index) => (
                        <div className="bg-dark-subtle p-2 text-wrap rounded-2 mt-2">
                        <div key={index}
                             className="bg-success-subtle rounded p-2 d-flex justify-content-between">
                          <small>{item.msg} <small className="text-capitalize bg-dark badge ms-2 text-warning fw-bold">{item.ticketStatus === "close"? "Closed" : "Opened"}</small></small>
                          <small>{moment(item.createdAt).format("DD MMM, YYYY")}</small>
                        </div>
                          {item.follow_ups.map((rep,i)=>(
                            <div key={index}
                                 className="bg-secondary-subtle mt-2 rounded p-2 d-flex justify-content-between">
                              <small >{rep.msg}</small>
                              <small>{moment(rep.createdAt).format("DD MMM, YYYY")}</small>
                            </div>
                          ))}
                        </div>
                      ))
                    }
                  </div>


              }

              {currentTab === 2 && hasTickets.length <= 0 &&
                <div className="text-center bg-secondary-subtle rounded my-3 py-2">
                  <span>No ticket closed to show here.</span>
                </div>
              }
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}
