import { configureStore } from "@reduxjs/toolkit";
import {authReducer} from "./auth/auth";

const store = configureStore({
  reducer: {auth: authReducer},
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
});

export {store};
