import React, { useEffect, useRef } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
// import {reduxUpdateUserDetail} from "@/redux/module/auth";
import { useDocumentTitle } from "../../../../components/Common";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { reduxUpdateUserDetail } from "../../../../redux/auth/auth";

export default function Detail() {
  const nameRef = useRef(null);
  const dispatch = useDispatch();
  const uid =
    typeof window !== "undefined" ? sessionStorage.getItem("uid") : "";
  const detailsErrorMessage = useSelector(
    (state) => state.auth.detailsErrorMessage
  );
  const router = useNavigate();

  useEffect(() => {
    if (nameRef.current) {
      nameRef.current.focus();
    }
  }, []);
  useDocumentTitle("Personal Details");

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .required("Enter a valid full name")
      .max(150, "Full name should be 150 characters only."),
    email: Yup.string()
      .required("Enter a valid email")
      .matches(/^[^@]+@[^@]+\.[^@]+$/, "Enter a valid email")
      .max(150, "Email should be 150 characters only."),
    tNC: Yup.boolean()
      .required("Please accept Terms and Conditions")
      .oneOf([true], "Please accept Terms and Conditions"),
  });
  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        firstName: "",
        email: "",
        tNC: false,
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        dispatch(reduxUpdateUserDetail({ ...values, uid }));
        setTimeout(() => {
          setSubmitting(false);
        }, 1000);
      }}
      enableReinitialize
    >
      {({ isSubmitting, touched, errors, values }) => (
        <Form>
          <div className="form-group col-md-10 offset-lg-1">
            <div className="row gy-2 mt-3">
              <div className="col-md-12">
                <Field
                  type="text"
                  name="firstName"
                  className={`form-control text-center ${
                    touched.firstName && errors.firstName
                      ? "is-invalid"
                      : "border-dark-subtle"
                  }`}
                  placeholder="Enter your name"
                  value={values.firstName}
                  innerRef={nameRef}
                />
                <ErrorMessage
                  name="firstName"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <div className="col-md-12 py-2">
                <Field
                  type="text"
                  className={`form-control text-center ${
                    touched.email && errors.email
                      ? "is-invalid"
                      : "border-dark-subtle"
                  }`}
                  name="email"
                  placeholder="Enter your email"
                  value={values.email}
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="invalid-feedback"
                />
              </div>
              <div className="col-md-12">
                <Field
                  className={`form-check-input ${
                    touched.tNC && errors.tNC ? "" : "border-dark-subtle"
                  }`}
                  type="checkbox"
                  name="tNC"
                  id="tNC"
                />
                <label className="form-check-label ms-1" htmlFor="tNC">
                  <small>
                    I accept{" "}
                    <a target="_blank" href="/terms-conditions">
                      Terms and Conditions
                    </a>
                  </small>
                </label>
                <ErrorMessage
                  name="tNC"
                  component="div"
                  style={{
                    display: "block",
                    width: "100%",
                    marginTop: ".25rem",
                    fontSize: ".875em",
                    color: "var(--bs-form-invalid-color)",
                  }}
                />
              </div>
              <div className="col-md-12">
                <div>
                  <small className="text-danger">{detailsErrorMessage}</small>
                </div>
                <button
                  type="submit"
                  className="btn btn-dark mt-0"
                  disabled={isSubmitting}
                >
                  Submit
                </button>
                <div>
                  <button
                    onClick={() => {
                      sessionStorage.clear();
                      window.location.href = "/get-started";
                    }}
                    style={{
                      borderRadius: "3px",
                      marginTop: "0.1rem",
                      backgroundColor: "transparent",
                      border: "none",
                      color: "#0818f3",
                    }}
                    type="button"
                    disabled={isSubmitting}
                  >
                    <small>Change Mobile Number</small>
                  </button>
                  <span className="d-block mt-2">
                    {isSubmitting && "Submitting..."}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
