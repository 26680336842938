import Mobile from "./guest/mobile";
import Otp from "./guest/otp";
import Detail from "./guest/detail";
import {useEffect, useRef, useState} from "react";
import toast from "react-hot-toast";
import {useSelector} from "react-redux";

export default function OffCanvasRight({section} = {section: "mobile"}) {
  const loginStep = useSelector((state) => state.auth.loginStep);
  const userDetail = useSelector((state) => state.auth.userDetail);
  const popCloseRef = useRef(null);
  const [loginForm, setLoginForm] = useState(section)

  const loadSection = () => {
    if (loginStep === "mobile" || loginStep === 'mobile-initial')
      return <Mobile setLoginForm={setLoginForm}/>
    else if (loginStep === "otp")
      return <Otp setLoginForm={setLoginForm}/>
    else if (loginStep === "details")
      return userDetail?.email ? <>Logged in successfully.</> : <Detail/>;
  }

  useEffect(() => {
    if (loginStep === 'successfully_logged_in') {
      if(userDetail?.email) {
        setLoginForm("mobile")
        popCloseRef.current.click();
        if(loginStep === "successfully_logged_in") {
          toast('Successfully Logged in.', {
            duration: 3000,
            style: {
              backgroundColor: "#ffffff",
              borderLeft: "#157347 solid 5px",
            },
            icon: '👏',
            ariaProps: {
              role: 'status',
              'aria-live': 'polite',
            },
          });
        }
      }
    }
  }, [loginStep, userDetail?.email]);

  return (
    <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight"
         aria-labelledby="offcanvasRightLabel">
      <div className="offcanvas-header">
        <h5 id="offcanvasRightLabel">Login</h5>
        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                aria-label="Close" ref={popCloseRef}></button>
      </div>
      <div className="offcanvas-body">
        <div className="col-lg-12">
          <div className="card text-center p-3 mt-lg-5">
            <small className="text-center" style={{"letterSpacing": "1px"}}>
              Get Your Risk Profile
            </small>
            <h4 style={{"letterSpacing": "1px"}} className="fw-bold text-center">
              Just in a Few Minutes!
            </h4>
            {loadSection()}
          </div>
        </div>
      </div>
    </div>
  );
}
