export const  APP_URL =
  process.env.REACT_APP_APP_URL
    ? `${process.env.REACT_APP_APP_URL}`
    : "http://192.168.1.100:3000/";

export const  V1_API_ORIGIN =
  process.env.REACT_APP_REMOTE_ORIGIN
    ? `${process.env.REACT_APP_REMOTE_ORIGIN}`
    : "http://192.168.1.100/api/v1";

export const  CMOTS_API_ORIGIN =
  process.env.REACT_APP_CMOTS_API_ORIGIN
    ? `${process.env.REACT_APP_CMOTS_API_ORIGIN}/api`
    : "https://equitypanditfinancial.cmots.com/api";

export const GOOGLE_AUTH_TOKEN =
  process.env.REACT_APP_GOOGLE_AUTH_TOKEN
    ? process.env.REACT_APP_GOOGLE_AUTH_TOKEN
    : "498168426369-77a59l62eqv36os7bvml6qcaa8ip3dln.apps.googleusercontent.com";

// export const VERSION = 'v1';
// export const API_HOST = `https://eapi.equitypandit.co.in/node_api/api/${VERSION}`;

export const DIGIO_ENV = process.env.REACT_APP_DIGIO_ENV
  ? process.env.REACT_APP_DIGIO_ENV
  : 'sandbox';

export const RAZORPAY_KEY = 'rzp_test_ljksbyiLSbcMdG'
