import React, { useEffect, useRef } from "react";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDocumentTitle } from "../../../../components/Common";
import { reduxOtpVerify } from "../../../../redux/auth/auth";

export default function Otp() {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const otpRef = useRef(null);
  const mobile =
    typeof window !== "undefined" ? sessionStorage.getItem("mobile") : "";
  const loginStep = useSelector((state) => state.auth.loginStep);
  const otpError = useSelector((state) => state.auth.otpError);
  const userDetail = useSelector((state) => state.auth.userDetail);

  useEffect(() => {
    if (otpRef.current) {
      otpRef.current.focus();
    }
  }, [loginStep, mobile, navigation]);
  useDocumentTitle("OTP Verify");

  const validationSchema = Yup.object({
    otp: Yup.string()
      .required("Enter a valid OTP")
      .min(4, "OTP must be 4 digits.")
      .max(4, "OTP must be 4 digits."),
  });
  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        countryCode: "+91",
        mobile,
        otp: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        dispatch(reduxOtpVerify(values));
        if (otpError !== "") {
          setTimeout(() => {
            resetForm();
          }, 500);
        }
        // if(!userDetail?.email) {
        //     // setLoginForm('details')
        // }
      }}
      enableReinitialize
    >
      {({ setFieldValue, isSubmitting, touched, errors, values }) => (
        <Form>
          <div className="form-group col-md-10 offset-lg-1">
            <div className="bg-success bg-opacity-10 my-3 p-2 border rounded-3" role="alert">
              <small>
                Enter OTP Sent on{" "}
                <span>
                  {mobile.slice(0, 2)}******{mobile.slice(8, 10)}
                </span>
              </small>
            </div>
            {/* <p className="mb-2 mt-3 text-center">OTP</p> */}
            <Field
              className={`form-control ${
                touched.otp && errors.otp ? "is-invalid" : "border-dark-subtle"
              }`}
              placeholder="Enter OTP"
              name="otp"
              style={{
                fontSize: "18px",
                letterSpacing: "2px",
                textAlign: "center",
              }}
              value={values.otp}
              onChange={(e) => {
                let otpValue = e.target.value;
                otpValue = otpValue.replace(/\D/g, "");
                otpValue = otpValue.slice(0, 4);
                setFieldValue("otp", otpValue);
              }}
              innerRef={otpRef}
            />
            {otpError ? (
              <div className="text-danger">{otpError}</div>
            ) : (
              <ErrorMessage
                name="otp"
                component="div"
                className="invalid-feedback"
              />
            )}

            <button
              type="submit"
              disabled={isSubmitting}
              className="btn btn-dark"
              style={{ borderRadius: "3px", marginTop: "15px" }}
            >
              Verify OTP
            </button>
            <div>
              <button
                onClick={() => {
                  window.location.href = "/get-started";
                }}
                style={{
                  borderRadius: "3px",
                  marginTop: "0.1rem",
                  backgroundColor: "transparent",
                  border: "none",
                  color: "#0818f3",
                }}
                type="button"
                disabled={isSubmitting}
              >
                <small>Change Mobile Number</small>
              </button>
              <span className="d-block mt-2">
                {isSubmitting && "Submitting..."}
              </span>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
