import React from 'react';
import Layout from "../layout/interface/Layout";
import {useDocumentTitle} from "../../components/Common";

const InvestorCharter = () => {
  useDocumentTitle('Investor Charter');
  return (
    <Layout>
    <section className="my-3">
      <div className="container py-lg-5 pt-5 pb-0">
        <div className="col-10 pt-lg-2">
          <b>Investor Charter in respect of Investment Adviser (IA)</b>
          <br/><br/>
          <b>A.Vision and Mission Statements for investors</b>
          <br/>
          <b>Vision</b>
          <br/>Invest with knowledge &amp; safety.<br/>
          <b>Mission</b>
          <br/>
          Every investor should be able to invest in right investment products based on
          their needs, manage and monitor them to meet their goals, access reports and
          enjoy financial wellness.
          <br/><br/>
          <b>B.Details of business transacted by the Investment Adviser with respect to the investors</b>
          <br/>
          -To enter into an agreement with the client providing all details
          including fee details, aspect of Conflict of interest disclosure and
          maintaining confidentiality of information.
          <br/>
          -To do a proper and unbiased risk – profiling and suitability
          assessment of the client.
          <br/>
          -To obtain registration with Know Your Client Registration
          Agency (KRA) and Central Know Your Customer Registry (CKYC).
          <br/>
          -To conduct audit annually.
          <br/>
          -To disclose the status of complaints in its website.
          <br/>
          -To disclose the name, proprietor name, type of
          registration, registration number, validity,
          complete address with telephone numbers and
          associated SEBI regional/local Office details in its
          website.
          <br/>
          -To employ only qualified and certified
          employees.
          <br/>
          -To deal with clients only from official
          number.
          <br/>
          -To maintain records of interactions,
          with all clients including prospective
          clients (prior to onboarding), where any
          conversation related to advice has taken
          place.
          <br/><br/>
          <b>C.Details of services provided to investors (No Indicative Timelines)</b>
          <br/>
          -Onboarding of Clients
          <br/>
          - Sharing of agreement copy
          <br/>
          - Completing KYC of clients
          <br/><br/>
          <b>Disclosure to Clients</b>
          <br/>
          -To provide full disclosure about its business, affiliations, compensation in the agreement.
          <br/>-To not access client’s accounts or holdings for offering advice.
          <br/>-To disclose the risk profile to the client.
          <br/>-To provide investment advice to the client based on the risk-profiling of the
          <br/>clients and suitability of the client.
          <br/><br/>
          <b>D.Details of grievance redressal mechanism and how to access it</b>
          <br/>1.In case of any grievance / complaint, an investor should approach the concerned Investment
          Adviser and shall ensure that the grievance is resolved within 30 days.
          <br/>2.If the investor’s complaint is not redressed satisfactorily, one may lodge a complaint with
          SEBI on {"SEBI’s 'SCORES'"} portal which is a centralized web based complaints redressal system. SEBI
          takes up the complaints registered via SCORES with the concerned intermediary for timely redressal.
          SCORES facilitates tracking the status of the complaint.
          <br/>3.With regard to physical complaints, investors may send their complaints to: Office of
          Investor Assistance and Education, Securities and Exchange Board of India, SEBI Bhavan, Plot No.
          C4-A, ‘G’ Block, Bandra-Kurla Complex, Bandra (E), Mumbai - 400 051.
          <br/><br/>
          <b>E.Expectations from the investors (Responsibilities of investors)</b>
          <br/><br/>
          <b>Do’s</b>
          <br/>i.Always deal with SEBI registered Investment Advisers.
          <br/>ii.Ensure that the Investment Adviser has a valid registration certificate.
          <br/>iii.Check for SEBI registration number. Please refer to the list of all SEBI registered
          Investment Advisers which is available on SEBI website in the following link:
          <br/>https://www.sebi.gov.in/sebiweb/other/OtherAction.do?doRecognisedFpi=yes&amp;intm Id=13)
          <br/>iv.Pay only advisory fees to your Investment Adviser. Make payments of advisory fees through
          banking channels only and maintain duly signed receipts mentioning the details of your payments.
          <br/>v.Always ask for your risk profiling before accepting investment advice. Insist that Investment
          Adviser provides advisory strictly on the basis of your risk profiling and take into account
          available investment alternatives.
          <br/>vi.Ask all relevant questions and clear your doubts with your Investment Adviser before acting
          on advice.
          <br/>vii.Assess the risk–return profile of the investment as well as the liquidity and safety
          aspects before making investments.
          <br/>viii.Insist on getting the terms and conditions in writing duly signed and stamped. Read these
          terms and conditions carefully particularly regarding advisory fees, advisory plans, category of
          recommendations etc. before dealing with any Investment Adviser.
          <br/>
          ix.Be vigilant in your transactions.
          <br/>x.Approach the appropriate authorities for redressal of your doubts / grievances.
          <br/>xi.Inform SEBI about Investment Advisers offering assured or guaranteed returns.
          <br/>
          <br/><b>Don’ts</b>
          <br/>xii.Don’t fall for stock tips offered under the pretext of investment advice.
          <br/>xiii.Do not provide funds for investment to the Investment Adviser.
          <br/>xiv.Don’t fall for the promise of indicative or exorbitant or assured returns by the Investment
          Advisers. Don’t let greed overcome rational investment decisions.
          <br/>xv.Don’t fall prey to luring advertisements or market rumours.
          <br/>xvi.Avoid doing transactions only on the basis of phone calls or messages from any Investment
          adviser or its representatives.
          <br/>xvii.Don’t take decisions just because of repeated messages and calls by Investment Advisers.
          <br/>xviii.Do not fall prey to limited period discount or other incentive, gifts, etc. offered by
          Investment advisers.
          <br/>xix.Don’t rush into making investments that do not match your risk taking appetite and
          investment goals.
          <br/>xx.Do not share login credential and password of your trading and demat accounts with the
          Investment Adviser.
          <br/>(In accordance with disclosure requirements under SEBI circular dated 13th December 2021)
        </div>
      </div>
    </section>
    </Layout>
  );
};

export default InvestorCharter;
