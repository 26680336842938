import Layout from "../layout/interface/Layout";
import React, {useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {IndianRupee} from "lucide-react";
import {handleScrollToTop} from "../../components/Common";

export const MakePayment = () => {
  const [buttonText, setButtonText] = useState("copy");
  const textToSelect = useRef(null);

  const handleCopy = () => {
    navigator.clipboard.writeText('pay.equitypandit@okhdfcbank')
      .then(() => {
        if (textToSelect.current) {
          // Select the text in the input field
          textToSelect.current.select();
          textToSelect.current.setSelectionRange(0, textToSelect.current.value.length);
          setButtonText("copied");
        }
      })
      .catch(err => {
        console.error('Error copying text: ', err);
      });
  };

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const amount = queryParams.get('amount');
  const router = useNavigate();
  function handleRedirect(link) {
    router(link);
    handleScrollToTop();
  }
  return (
    <Layout>
      <div className="container-fluid my-3">
        <div className="bg-white rounded-3 border">
          <div className="row">


            <div className="col-md-12 px-5 py-1 text-center border-dark-subtle border-2">
              <div className="mt-2 mx-2 text-center">
                <h4 className="border border-2 border-info rounded py-1">Payable amount
                  - <IndianRupee/>{parseInt(amount).toLocaleString("en-US")}</h4>
              </div>
            </div>

            <div className="col-md-6 p-5 text-center border-dark-subtle border-2">
              <h1>For UPI Payments</h1>
              <h5>Scan For Google Play, Paytm or PhonePay etc.</h5>
              <img
                src="/assets/img/upi_equitypandit.jpeg"
                alt="UPI equitypandit"
                style={{width: '250px'}}
              />
              <div>
                <div className="btn-group" role="group">
                  <input
                    ref={textToSelect}
                    readOnly={true}
                    value="pay.equitypandit@okhdfcbank"
                    className="text-center"
                    style={{outline: 'none', border: 'none', backgroundColor: "#ededed"}}
                  />
                  <button
                    type="button"
                    className={`btn ${buttonText === 'copy' ? 'btn-primary' : 'btn-success'}`}
                    onClick={handleCopy}
                  >
                    {buttonText}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-6 p-5">
              <h4>For NEFT / RTGS / Cheque Payments</h4>
              <div className="mt-5">
                <img src="/ep-imgs/bank-image.png" width="100"/>
                <div className="mt-3">
                  <span className="text-secondary">Account Name:</span> EquityPandit Financial Services Pvt Ltd.
                </div>
                <div className="mt-3">
                  <span className="text-secondary">Account Number:</span> 50200009691078
                </div>
                <div className="mt-3">
                  <span className="text-secondary">Bank Name:</span> HDFC Bank (L P SAVANI Branch)
                </div>
                <div className="mt-3">
                  <span className="text-secondary">IFSC Code:</span> HDFC0001701
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="bg-green bg-opacity-10">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center py-5">
                <h1 className="fw-bold">
                  Need any help? Contact To Support Team!
                </h1>
                <p>
                  We are always there for you every time and will guide you at
                  every step. <br/>
                  The team of certified advisors will assist you in achieving
                  the wealth creation goal.
                </p>
                <div className="d-flex text-success justify-content-center fw-bold">
                  <p>+91 8000 816 688</p>
                  <p className="mx-2"> | </p>
                  <p>support@equitypandit.com</p>
                </div>
                <button
                  className="btn btn-dark"
                  onClick={() => handleRedirect("/contact-us")}
                >
                  Contact Us
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}
