import axios from "axios";
import {V1_API_ORIGIN} from "../../../config/constants.config";

export const previousCallsService = (pacId) => {
  return axios.get(`${V1_API_ORIGIN}/messages/get-prev-messages?currentPage=1&perPage=100&pacId=${pacId}`);
}

export const servicesService = () => {
  if (typeof window !== 'undefined') {
    const uid = sessionStorage.getItem("uid");
    return axios.get(`${V1_API_ORIGIN}/users/services?userId=${uid}`);
  }
}
