import axios from "axios";
import {V1_API_ORIGIN} from "../../../config/constants.config";

export const myServiceService = () => {
  if (typeof window !== 'undefined') {
    const uid = sessionStorage.getItem("uid");
    return axios.get(`${V1_API_ORIGIN}/users/services?userId=${uid}`);
  }
}

export const checkPaymentService = (pid) => {
  return axios.get(`${V1_API_ORIGIN}/payments/${pid}`);
}

export const activeServices = (pid) => {
  return axios.get(`${V1_API_ORIGIN}/payments/active-services/${pid}`);
}
