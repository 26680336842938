import {LiveCallsService, servicesService} from "../service/live-calls.service";
import {useEffect, useState} from "react";
import moment from "moment";
// import {useDocumentTitle} from "@/common/common";
import Layout from "../../layout/interface/Layout";

export default function LiveCalls() {
  const [hasServices, setHasServices] = useState([]);
  const [selectedService, setSelectedService] = useState("");
  const [hasLiveCalls, setHasLiveCalls] = useState([]);

  const fetchServices = () => {
    return servicesService()
      .then((response) => {
        setHasServices(response.data.data);
      })
  }
  // useDocumentTitle('Live Calls');

  useEffect(() => {
    LiveCallsService(selectedService)
      .then((response) => {
        setHasLiveCalls(response.data.data);
      });
  }, [selectedService]);

  useEffect(() => {
    fetchServices();
  }, []);

  return (
    <Layout>
      <section className="bg-success bg-opacity-25">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="card p-3 mt-lg-5">
                <h6 className="mb-0 fs-24 fw-bold mb-3">Live Calls</h6>
                <div className="row">
                  <div className="col-lg-4 mb-2 d-none">
                    <select className="form-select"
                            onChange={(e) => setSelectedService(e.target.value)}>
                      <option value="">Select a package</option>
                      {
                        (hasServices || []).map(item => (
                          <option key={item.pacId} value={item.pacId}>{item.pacName}</option>
                        ))
                      }
                    </select>
                  </div>
                  <div className="col-lg-12">

                    {
                      (hasLiveCalls || []).map((item, index) => (
                        <div key={index} className={`bg-secondary bg-opacity-10 mb-2 d-flex flex-column rounded-3 py-3 px-2 border-3 border-start w-100
                                                ${item.callType === "MAIN"
                          ? "border-success"
                          : "border-secondary"}`}>
                          {item.callType === "MAIN"
                            ?
                            <div>
                              <div className="d-flex mb-2">
                                <small className="badge text-success bg-opacity-50">
                                  New Call
                                </small>
                                <small className="badge bg-opacity-25"
                                       style={{backgroundColor: "#f55505"}}>{item.pacInvestmentType}</small>
                                <small className="ms-auto badge text-secondary fw-normal">
                                  {moment(item.epDateTime).format("DD MMM YYYY, HH:MM:SS A")}
                                </small>
                              </div>
                              <small>{item.serName}: {item.serMsg}</small>
                            </div>
                            : <div>
                              <div className="d-flex mb-2">
                                <small className="badge text-secondary bg-opacity-50">
                                  Follow Up
                                </small>
                                <small className="badge bg-opacity-25"
                                       style={{backgroundColor: "#f55505"}}>{item.pacInvestmentType}</small>
                                <small
                                  className="ms-auto badge text-secondary text-uppercase">
                                  {moment(item.epDateTime).format("YYYY-MM-DD HH:MM:SS A")}
                                </small>
                              </div>
                              <small>{item.serName}: {item.serMsg}</small>
                              <div className="bg-primary-subtle p-1 rounded px-3 opacity-75"
                                   style={{fontSize: "14px"}}>
                                <small>{item.serName}: {item.main_ser_msg}</small>
                              </div>
                            </div>}
                        </div>
                      ))
                    }
                    {
                      hasLiveCalls.length <= 0 &&
                      <div className="mt-4 text-center bg-info rounded py-2 bg-opacity-75">No calls
                        Yet</div>
                    }
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}
