import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import {APP_URL, V1_API_ORIGIN} from "../../../config/constants.config";

const TopBanner = () => {
  const navigate = useNavigate();
  const [isShowExpiring, setIsShowExpiring] = useState({
    showBanner: false,
    message: "",
  });
  // const subscriptionDetails = () => {
  //   axios
  //     .get(`${V1_API_ORIGIN}/subscriptions/my-subscriptions`)
  //     .then((response) => {
  //       setIsShowExpiring((prevState) => {
  //         let message;
  //         if(["subscribed", "expired", "expiring_soon"].includes(response?.data?.data?.subscriptionStatus)) {
  //           if (response?.data?.data?.isSubscribed) {
  //             const daysRemaining = response?.data?.data?.dataList[0]?.leftDays;
  //             const showBanner = daysRemaining <= 8;
  //             if (daysRemaining === 0) {
  //               message = "Your Unicorn PRO Service is expiring today.";
  //             } else if (daysRemaining === 1) {
  //               message = "Your Unicorn PRO Service expiring tomorrow.";
  //             } else {
  //               message = `Your Unicorn PRO Service expiring in ${daysRemaining} days.`;
  //             }
  //             return {...prevState, showBanner, daysRemaining, message};
  //           } else {
  //             message = "Your Unicorn PRO service has expired!";
  //             return {...prevState, showBanner: true, message};
  //           }
  //         }
  //         return {...prevState, showBanner: false};
  //       });
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };
  //
  // useEffect(() => {
  //   subscriptionDetails();
  // }, []);
  const handleRenewNow = () => {
    navigate("/payment-details");
  };
  return (
    <>
      {window.location.pathname === "/dashboard" ? (
        <section className="d-none" style={{ backgroundColor: "#22c03c40" }}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex text justify-content-center align-items-center py-2">
                  <small>Download Unicorn Signals</small>
                  <div className="d-flex">
                    <Link
                      className="d-flex align-items-center text-black text-center"
                      to="https://play.google.com/store/apps/details?id=com.unicorn.signals"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="bg-white rounded-1 p-1 ms-1">
                        <img
                          src={`${APP_URL}/defaults/google_play.png`}
                          className="img-fluid"
                          width="80"
                          height="22"
                          alt={"google_play"}
                        />
                      </span>
                    </Link>
                    <Link
                      className="d-flex align-items-center text-black text-center"
                      to="https://apps.apple.com/in/app/unicorn-signals-trading-view/id1658748586"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="bg-white rounded-1 ms-1">
                        <img
                          src={`${APP_URL}/defaults/google_app.png`}
                          className="img-fluid"
                          width="80"
                          height="22"
                          alt={"apple_app"}
                        />
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}

      {isShowExpiring.showBanner ? (
        <section className="bg-danger bg-gradient">
          <div className="container-fluid">
            <div className="row justify-content-center py-2 align-items-center">
              <div className="col-auto">
                <div className="d-flex justify-content-center align-items-center">
                  <small className="mb-0 me-3 fw-semibold text-light">
                    <i className="fa-solid fa-triangle-exclamation"></i>{" "}
                    {isShowExpiring.message}
                  </small>
                </div>
              </div>
              <div className="col-auto">
                <button
                  className="btn btn-sm btn-light fw-bold"
                  style={{
                    "--bs-btn-padding-y": ".20rem",
                    "--bs-btn-padding-x": ".5rem",
                    "--bs-btn-font-size": ".75rem",
                  }}
                  onClick={() => handleRenewNow()}
                >
                  Renew Now
                </button>
              </div>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
    </>
  );
};

export default TopBanner;
