import {useEffect, useRef, useState} from "react";
import {
  getFilledRiskProfileService,
  getRiskProfileService,
  submitRiskProfile,
} from "../service/risk-profile.service";
// import { setAfterAuthUrl } from "@/redux/module/routing";
import { InfoIcon } from "lucide-react";
// import { reduxFetchUserDetail } from "@/redux/module/auth";
// import { useRouter } from "next/navigation";
import { toWords } from "number-to-words";
import moment from "moment";
// import { useDocumentTitle } from "@/common/common";
import {useDispatch, useSelector} from "react-redux";
import {reduxFetchUserDetail} from "../../../redux/auth/auth";
import {useNavigate} from "react-router-dom";
import Layout from "../../layout/interface/Layout";

export default function RiskProfile() {
  const dispatch = useDispatch();
  const router = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const userId = sessionStorage.getItem("uid");
  const userDetail = useSelector((state) => state.auth.userDetail);
  const [hasQuestion, setHasQuestion] = useState([]);
  const [isShowTooltip, setIsShowTooltip] = useState({
    monthlyIncome: false,
    dob: false,
  });
  const firstInput = useRef(null);
  const [hasAns, setHasAns] = useState({
    userId,
    dob: "",
    occupation: "",
    investmentGoal: "",
    monthlyIncome: "",
    userAnswers: [],
  });

  useEffect(() => {
    if(firstInput.current) {
      firstInput.current.focus()
    }
    if (
      userDetail.rpfProfileType === "Aggressive" ||
      userDetail.email === null
    ) {
      router("/dashboard");
    }
    // else if(userDetail.rpfProfileType === null) {
    setIsLoading(false);
    // }
    getRiskProfileService()
      .then((response) => {
        setHasQuestion(response.data.data);
        let userAnswers = [];
        response.data.data.map((item) => {
          userAnswers.push({
            queId: item.id,
            queCategory: item.queCategory,
            optId: 0,
            optSeq: "",
            optWeight: "",
          });
        });
        setHasAns((prevState) => {
          return { ...prevState, userAnswers };
        });
      })
      .then(() => {
        if (userDetail.rpfProfileType) {
          if (userDetail.rpfId)
            getFilledRiskProfileService(userDetail.rpfId).then((response) => {
              setHasAns(() => {
                return { ...response };
              });
            });
        }
      });
  }, [dispatch, userDetail]);
  // useDocumentTitle("Risk Profile");

  const updateAns = (params) => {
    setHasAns((prevState) => {
      const { queId, queCategory, optId, optSeq, optWeight } = params;
      let userAnswersCloned = [...hasAns.userAnswers];
      const index = hasAns.userAnswers.findIndex(
        (item) => item.queId === queId
      );
      if (index !== -1) {
        userAnswersCloned[index] = {
          queId,
          queCategory,
          optId,
          optSeq,
          optWeight,
        };
      }
      return { ...prevState, userAnswers: userAnswersCloned };
    });
  };

  const submit = (e) => {
    e.preventDefault();
    submitRiskProfile(hasAns)
      .then(async () => {
        await dispatch(reduxFetchUserDetail(userId));
      })
      .then(() => {
        router("/dashboard");
      });
  };

  // const [hasAge, setHasAge] = useState(0);
  // useEffect(() => {
  //
  // }, [hasAns.dob]);

  if (isLoading) {
    return <div></div>;
  } else {
    return (
      <Layout>
        <section className="align-items-center bg-green pb-0">
          <div className="container">
            <div className="row py-5 align-items-center">
              <div className="col-md-6">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb"></ol>
                </nav>
                <h1 className="fw-bold text-black">Risk Profiling</h1>
                <p style={{ letterSpacing: "1px" }}>
                  You are just one step away to get your free financial plan.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="pt-5">
          <div className="container">
            <div className="row justify-content-center g-3">
              <div className="col-md-10">
                <form onSubmit={submit}>
                  <div className="row">
                    <div className="col-md-3 col-12">
                      <div className="mb-4">
                        <div className="d-flex justify-content-between">
                          <label
                            className="mb-1 fw-bold rpf_question"
                            htmlFor="dob"
                          >
                            Date of Birth
                          </label>
                          <a
                            href="#"
                            onMouseEnter={() =>
                              setIsShowTooltip({
                                ...isShowTooltip,
                                dob: true,
                              })
                            }
                            onMouseLeave={() =>
                              setIsShowTooltip({
                                ...isShowTooltip,
                                dob: false,
                              })
                            }
                          >
                            <InfoIcon size={20} className="font-primary" />
                          </a>

                          {isShowTooltip.dob && (
                            <div
                              className="tooltip bs-tooltip-auto fade show"
                              role="tooltip"
                              style={{
                                position: "absolute",
                                inset: "0px auto auto -712px",
                                margin: "0px",
                                transform: "translate(1145px, 240px)",
                              }}
                              data-popper-placement="right"
                            >
                              <div
                                className="tooltip-arrow"
                                style={{
                                  position: "absolute",
                                  top: "-64px",
                                  transform: "translate(0px, 100px)",
                                }}
                              ></div>
                              <div className="tooltip-inner">
                                Why do we require your birth date? <br /> Date
                                of birth is a key factor in assessing your risk
                                profile. It helps us determine the most suitable
                                level of risk for your investments based on your
                                age and financial objectives.
                              </div>
                            </div>
                          )}
                        </div>
                        <input
                          ref={firstInput}
                          type="date"
                          className="shadow-none py-2 form-control text-black dob"
                          required={true}
                          name="dob"
                          id="dob"
                          max="2006-07-17"
                          value={hasAns.dob}
                          onChange={(e) => {
                            setHasAns({ ...hasAns, dob: e.target.value });
                            window.hasAge = moment().diff(
                              moment(e.target.value, "YYYY-MM-DD"),
                              "years"
                            );
                            let optId;
                            let optSeq;
                            let optWeight;
                            if (hasAge < 23) {
                              optId = 1;
                              optSeq = "A";
                              optWeight = "2";
                            } else if (hasAge > 23 && hasAge < 40) {
                              optId = 2;
                              optSeq = "B";
                              optWeight = "4";
                            } else if (hasAge > 40 && hasAge < 60) {
                              optId = 3;
                              optSeq = "C";
                              optWeight = "3";
                            } else if (hasAge > 60) {
                              optId = 4;
                              optSeq = "D";
                              optWeight = "1";
                            }
                            updateAns({
                              queId: 1,
                              queCategory: "WILLINGNESS TO TAKE RISK",
                              optId,
                              optSeq,
                              optWeight,
                            });
                          }}
                        />
                        <small
                          id="currencyOutput"
                          className={`${
                            window.hasAge < 18 ? "text-danger" : "text-success"
                          }`}
                        >
                          {" "}
                          {window.hasAge < 18 &&
                            "Minimum age 18 years but"}{" "}
                          {window.hasAge
                            ? `${window.hasAge < 18 ? "you're" : "You're"} ${
                              window.hasAge
                            } years old`
                            : ""}
                        </small>
                        <span className="dob_error_msg"></span>
                      </div>
                    </div>
                    <div className="col-md-3 col-12">
                      <div className="mb-4">
                        <label
                          className="mb-1 fw-bold rpf_question"
                          htmlFor="occupation-radio"
                        >
                          Occupation
                        </label>
                        <select
                          name="occupation-radio"
                          id="occupation-radio"
                          required={true}
                          value={hasAns.occupation}
                          className="form-select"
                          onChange={(e) => {
                            setHasAns({
                              ...hasAns,
                              occupation: e.target.value,
                            });
                            const input = e.target.value;
                            let optId;
                            let optSeq;
                            let optWeight;
                            if (input === "Business or Professional") {
                              optId = 5;
                              optSeq = "A";
                              optWeight = "5";
                            } else if (
                              input === "Service - Private or Public Sector"
                            ) {
                              optId = 6;
                              optSeq = "B";
                              optWeight = "4";
                            } else if (input === "Government Job") {
                              optId = 7;
                              optSeq = "C";
                              optWeight = "3";
                            } else if (input === "Retired") {
                              optId = 8;
                              optSeq = "D";
                              optWeight = "2";
                            } else if (input === "Housewife") {
                              optId = 9;
                              optSeq = "E";
                              optWeight = "1";
                            }
                            updateAns({
                              queId: 2,
                              queCategory: "FINANCIAL CAPABILITY",
                              optId,
                              optSeq,
                              optWeight,
                            });
                          }}
                        >
                          <option value="">-- Select --</option>
                          <option value="Business or Professional">
                            Business or Professional
                          </option>
                          <option value="Service - Private or Public Sector">
                            Service - Private or Public Sector
                          </option>
                          <option value="Government Job">Government Job</option>
                          <option value="Retired">Retired</option>
                          <option value="Housewife">Housewife</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3 col-12">
                      <div className="mb-4">
                        <label
                          className="mb-1 fw-bold rpf_question"
                          htmlFor="investment-goals-radio"
                        >
                          Investment Goals
                        </label>
                        <select
                          name="investment-goals-radio"
                          id="investment-goals-radio"
                          required={true}
                          value={hasAns.investmentGoal}
                          className="form-select"
                          onChange={(e) => {
                            setHasAns({
                              ...hasAns,
                              investmentGoal: e.target.value,
                            });
                            const input = e.target.value;
                            let optId;
                            let optSeq;
                            let optWeight;
                            if (input === "Capital Appreciation") {
                              optId = 10;
                              optSeq = "A";
                              optWeight = "2";
                            } else if (
                              input ===
                              "Regular Income while taking calculated risk"
                            ) {
                              optId = 11;
                              optSeq = "B";
                              optWeight = "3";
                            } else if (input === "Combination Of all") {
                              optId = 12;
                              optSeq = "C";
                              optWeight = "3";
                            }
                            updateAns({
                              queId: 3,
                              queCategory: "WILLINGNESS TO TAKE RISK",
                              optId,
                              optSeq,
                              optWeight,
                            });
                          }}
                        >
                          <option value="">-- Select --</option>
                          <option value="Capital Appreciation">
                            Capital Appreciation
                          </option>
                          <option value="Regular Income while taking calculated risk">
                            Regular Income while taking calculated risk
                          </option>
                          <option value="Combination Of all">
                            Combination Of all
                          </option>
                        </select>
                        <span className="text-danger investment-goals-radio_error_msg"></span>
                      </div>
                    </div>
                    <div className="col-md-3 col-12">
                      <div className="mb-4">
                        <div className="d-flex justify-content-between">
                          <label
                            className="mb-1 fw-bold rpf_question"
                            htmlFor="monthly-income-radio"
                          >
                            Monthly Income
                          </label>
                          <a
                            href="#"
                            onMouseEnter={() =>
                              setIsShowTooltip({
                                ...isShowTooltip,
                                monthlyIncome: true,
                              })
                            }
                            onMouseLeave={() =>
                              setIsShowTooltip({
                                ...isShowTooltip,
                                monthlyIncome: false,
                              })
                            }
                          >
                            <InfoIcon size={20} className="font-primary" />
                          </a>

                          {isShowTooltip.monthlyIncome && (
                            <div
                              className="tooltip bs-tooltip-auto fade show"
                              role="tooltip"
                              style={{
                                position: "absolute",
                                inset: "0px auto auto 0px",
                                margin: "0px",
                                transform: "translate(1145px, 240px)",
                              }}
                              data-popper-placement="right"
                            >
                              <div
                                className="tooltip-arrow"
                                style={{
                                  position: "absolute",
                                  top: "-64px",
                                  transform: "translate(0px, 100px)",
                                }}
                              ></div>
                              <div className="tooltip-inner">
                                Why do we require your monthly income? <br />
                                Income is a fundamental aspect as it aids us in
                                determining the right level of risk for your
                                investments/trading, taking into account your
                                financial capability and long-term financial
                                goals.
                              </div>
                            </div>
                          )}
                        </div>
                        <input
                          id="monthly-income-radio"
                          type="text"
                          maxLength={12}
                          className="shadow-none py-2 form-control text-black"
                          required={true}
                          value={hasAns.monthlyIncome}
                          onChange={(e) => {
                            if(!isNaN(e.target.value)) {
                              const yearlyIncome = +e.target.value * 12;
                              let optId;
                              let optSeq;
                              let optWeight;
                              if (yearlyIncome < 500000) {
                                optId = 14;
                                optSeq = "A";
                                optWeight = "1";
                              } else if (
                                yearlyIncome >= 500000 &&
                                yearlyIncome < 1000000
                              ) {
                                optId = 15;
                                optSeq = "B";
                                optWeight = "2";
                              } else if (
                                yearlyIncome >= 1000000 &&
                                yearlyIncome < 2500000
                              ) {
                                optId = 16;
                                optSeq = "C";
                                optWeight = "3";
                              } else if (yearlyIncome >= 2500000) {
                                optId = 17;
                                optSeq = "D";
                                optWeight = "4";
                              }
                              setHasAns((prevState) => ({
                                ...prevState,
                                monthlyIncome: e.target.value,
                              }));
                              // setHasAns({...hasAns, monthlyIncome: String(input).slice(0,12)})
                              updateAns({
                                queId: 4,
                                queCategory: "FINANCIAL CAPABILITY",
                                optId,
                                optSeq,
                                optWeight,
                              });
                            }
                          }}
                        />
                        {hasAns.monthlyIncome && (
                          <small
                            id="currencyOutput"
                            className={`${
                              hasAns.monthlyIncome < 10000
                                ? "text-danger"
                                : "text-success"
                            }`}
                          >
                            {hasAns.monthlyIncome < 10000 &&
                              "Minimum ten thousand monthly income required but "}{" "}
                            {hasAns.monthlyIncome < 10000
                              ? "Your monthly income is"
                              : ""}
                            {hasAns.monthlyIncome &&
                              toWords(+hasAns.monthlyIncome ?? 0)}{" "}
                            only.
                          </small>
                        )}
                        <span className="text-danger monthly-income-radio_error_msg"></span>
                      </div>
                    </div>
                  </div>
                  <div className="row g-1">
                    {hasQuestion.map((item, index) => {
                      if (![1, 2, 3, 4].includes(index + 1))
                        return (
                          <div key={index} className="col-lg-6 col-12 ">
                            <div
                              className="border-dark-subtle border border-1 rounded-3 bg-info bg-opacity-10"
                              style={{ height: "16rem" }}
                            >
                              <div className="p-2">
                                <p className="rpf_question">
                                  {index - 3}. {item.question}
                                </p>
                                <div className="">
                                  {(item?.options || []).map(
                                    (item2, index2) => (
                                      <div
                                        className="form-check mb-2"
                                        key={index2}
                                      >
                                        <input
                                          id={item2.queId + item2.id}
                                          name={item2.queId}
                                          type="radio"
                                          checked={
                                            hasAns.userAnswers[index].optSeq ===
                                            item2.optSeq
                                          }
                                          className="form-check-input"
                                          required={true}
                                          onChange={() =>
                                            updateAns({
                                              queId: item2.queId,
                                              queCategory: item.queCategory,
                                              optId: item2.id,
                                              optSeq: item2.optSeq,
                                              optWeight: item2.optWeight,
                                            })
                                          }
                                        />
                                        <small
                                          className="form-check-label fst-italic"
                                          style={{
                                            cursor: "pointer",
                                            userSelect: "none",
                                          }}
                                          htmlFor={item2.queId + item2.id}
                                          onClick={() =>
                                            updateAns({
                                              queId: item2.queId,
                                              queCategory: item.queCategory,
                                              optId: item2.id,
                                              optSeq: item2.optSeq,
                                              optWeight: item2.optWeight,
                                            })
                                          }
                                        >
                                          {item2.optText}
                                        </small>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                    })}
                  </div>
                  <div className="text-center mt-2">
                    <button
                      id="submit"
                      className="btn btn-success text-uppercase"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
