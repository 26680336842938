import React from 'react';
import Layout from "../layout/interface/Layout";
import {useDocumentTitle} from "../../components/Common";
import {useParams} from "react-router-dom";

const TermsConditions = () => {
  let {device} = useParams()

  const UI = (
    <>
      <section className="my-3">
        <div className="container py-lg-5 pt-5 pb-0">
          <div className="fs-2 text-black fw-bold mx-3 px-3 mx-lg-5 px-lg-4 mx-md-3 px-md-3">
            Terms &amp; Conditions
          </div>
          <div
            style={{textAlign: 'justify'}}
            className="mx-3 px-3 mx-lg-5 px-lg-4 mx-md-3 px-md-3"
          >
            <p>
              {`Equitypandit Financial Services Private Limited, known as "EquityPandit," "we," or "us,"
                            operates the website `}
              <a href="/">www.equitypandit.co.in</a>, {`referred to as the "Website/Site/Platform." Through this
                        platform, we provide specific information about services (collectively termed as "information,"
                        "content," or "material"), as outlined in these Terms and Conditions.`}
            </p>
            <p>
              {`By accessing our website, you agree to abide by the subsequent terms and conditions ("Terms of
                            Service," "Terms"), encompassing additional terms, conditions, and policies referenced herein or
                            available via hyperlink. These terms are applicable to all users or visitors of the site,
                            inclusive of individuals browsing, potential/existing vendors, content contributors, clients, or
                            any other parties, herein collectively referred to as "USERS" or "you."`}
            </p>
            <p>
              {`The following Terms of Use (referred to as the "Agreement") establish a legal contract between
                            each user of the website (referred to as "you"), acting as an individual and, if relevant, as
                            the entity you represent, and EquityPandit, governing the use of the website. EquityPandit
                            retains the right to modify these terms and conditions on the Website. It is your responsibility
                            to periodically review this page for any changes. By continuing to access or use our website
                            after EquityPandit has posted alterations to the Agreement, you will be considered to have
                            accepted those modifications.`}
            </p>
            <p>
              You may access and use the website only if you agree to abide by the following terms. We provide
              the information on the website to you, contingent upon your agreement to adhere to the terms and
              conditions set forth below. Your acceptance of the terms of this Agreement, and/or your access
              and use of the website, shall be considered as your acceptance of all the terms,
              representations, and commitments outlined in this Agreement, without any qualifications or
              exceptions. If you do not agree to be bound by this Agreement, you may not access or use the
              website.
            </p>
            <p>
              By clicking the Acceptance button or accessing, downloading, and/or using the Platform, you are
              electronically indicating the following:
            </p>
            <ol type="a">
              <li>You consent to be bound by and comply with the terms outlined in this Agreement.</li>
              <li>You agree to adhere to the laws that are duly enforceable under the Applicable Laws of the
                Country.
              </li>
              <li>{`You acknowledge that the information displayed by us through the website is provided "AS IS"
                            and without warranties.`}
              </li>
              <li>You confirm that you will not utilize the website if such usage is prohibited or unlawful
                under your local laws.
              </li>
            </ol>
            <p>
              These Terms of Use, in conjunction with our Privacy Policy (each referenced herein and
              collectively forming this “Agreement”), govern your utilization of the website, whether as a
              user or visitor.
            </p>
            <ol>
              <li>Eligibility:</li>
            </ol>
            <p>
              You must be at least 18 years of age or older to access, use, or test the website in any manner.
              By accessing, using, or testing the website, or by accepting this Agreement, you confirm and
              guarantee to us that you are 18 years of age or older. Additionally, you assert that you possess
              the right, authority, and capacity to use the website and agree to abide by this Agreement. For
              users below the age of 18 years, parent(s) or guardian(s) shall be responsible for any actions
              performed by such users while using the website.
            </p>
            <ol start="2">
              <li>
                Website for Informational Purposes Only:
                <ol type="a">
                  <li>
                    {`You acknowledge and understand that this website is intended solely for
                                        informational purposes and not for advisory purposes. It is designed to provide
                                        information that is intended, although not assured, to be accurate, comprehensive,
                                        and current. The information and/or materials presented do not represent a
                                        solicitation of offers to buy or sell any products/securities or services. Users and
                                        Visitors to this website are strongly advised to seek advice from professional
                                        investment advisors/financial planners and thoroughly review the company's track
                                        record, offer document(s), prospectus, among other documents, before making any
                                        investment decisions based on the information available on the website.`}
                  </li>
                  <li>
                    Both Parties agree that mere visitation to the Website, submitting an inquiry, or
                    utilizing any information available on the site does not establish an advisor-client
                    relationship or render financial planning services in any manner. Should you desire
                    advisory services from EquityPandit, a formal agreement must be executed by
                    contacting us at 8000816688 or emailing us at support@equitypandit.com
                  </li>
                  <li>
                    By submitting your details to us, including your email address, you consent to
                    receive emails from EquityPandit (including our newsletter, promotional offers, and
                    announcements, among others). The collected information will not be shared with any
                    third party and complies with our stated Privacy Policy.
                  </li>
                </ol>
              </li>
            </ol>
            <p>&nbsp;</p>
            <ol start="3">
              <li>Intellectual Property Rights:</li>
              <ol type="a">
                <li>
                  You acknowledge and agree that all information, content material, and products
                  (including, but not limited to, text, content, photographs, graphics, video, and audio
                  content) on the website are protected under copyright laws. Unless explicitly stated in
                  writing, such content shall not be copied, transmitted, displayed, performed,
                  distributed (for compensation or otherwise), licensed, altered, framed, stored for
                  subsequent use, or otherwise used in whole or in part in any manner without written
                  permission, except as permitted under the Indian Copyright Act of 1957, as amended, and
                  then, only with the owner’s proprietary rights. Subject to any third-party data
                  exchange, you may download information and print a copy for personal use, provided you
                  do not remove any copyright or other notice contained in the downloaded information.
                </li>
                <li>
                  {`All trademarks, service marks, slogans, logos, trade dress, and other identifiers
                                    ("Marks") displayed on the website are the property of EquityPandit. The names of other
                                    companies and third-party products or services mentioned on the Website may be the
                                    trademarks or service marks of their respective owners. These Marks are safeguarded by
                                    the Trade Marks Act of 1999. You are prohibited from using any of the marks for any
                                    purpose, including but not limited to, use on other materials, in presentations, as
                                    domain names, or as metatags, without the express written permission of EquityPandit or
                                    the party that owns the mark.`}
                </li>
              </ol>
            </ol>
            <p>&nbsp;</p>
            <ol start="4">
              <li>Links to Other Websites:</li>
            </ol>
            <p>
              Any links provided on our website serve solely as pointers to information on topics that might
              be beneficial to you. We do not have control over the content of such unaffiliated websites. If
              you decide to visit a website that we do not manage, we will not bear any responsibility for it.
              The inclusion of links to unaffiliated websites does not imply any endorsement or assumption of
              responsibility for the opinions, ideas, products, services, or information presented on those
              sites. Additionally, third-party websites may have privacy policies or security practices that
              differ from our website. Therefore, EquityPandit disclaims any and all liabilities concerning
              these third-party links present on the Website.
            </p>
            <ol start="5">
              <li>
                User Responsibilities:
                <ol type="a">
                  <li>
                    {`By using the website's provision for a callback or submitting inquiries, you agree
                                        and acknowledge that doing so is voluntary. By utilizing the Enquiry form or
                                        requesting a callback, you authorize EquityPandit to respond to your inquiries by
                                        contacting you. However, merely submitting an inquiry form or receiving responses to
                                        your queries from EquityPandit does not establish a client-advisor relationship
                                        unless you enter into a formal agreement with EquityPandit appointing them as your
                                        Financial Advisor or for other services. Consequently, EquityPandit will not be held
                                        liable for any actions or consequences arising from your decisions made based on the
                                        information available on the website.`}
                  </li>
                  <li>
                    You acknowledge and agree that any submissions, including personal information
                    provided through the Enquiry form, request for a callback, or otherwise, are
                    governed by our privacy policy.
                  </li>
                </ol>
              </li>
            </ol>
            <p>&nbsp;</p>
            <ol start="6">
              <li>Disclaimer of Warranties and Liability:</li>
              <ol type="a">
                <li>
                  {`All information and materials on the Website are provided "AS IS" and solely for
                                    informational purposes, without any express or implied guarantees or warranties.
                                    EquityPandit explicitly disclaims liability for any form of loss, injury, or damage that
                                    may arise or be related to:`}
                  <ul>
                    <li>
                      {`Errors or omissions in the website's content, including technical inaccuracies
                                            and typographical errors.`}
                    </li>
                    <li>
                      Access to third-party websites or their content through links, directly or
                      indirectly, including any errors or omissions therein.
                    </li>
                    <li>Your usage of the Website in any manner.</li>
                  </ul>
                </li>
                <li>
                  EquityPandit, its partners, employees, or representatives will not be held liable under
                  any circumstances for any direct or consequential loss resulting from the use of
                  information, data, or material contained on the Site, including but not limited to:
                  <ul>
                    <li>
                      Loss, injury, claim, liability, or damages resulting from using or being unable
                      to use the information, links, or materials on the Site.
                    </li>
                    <li>
                      Special, direct, incidental, punitive, exemplary, or consequential damages of
                      any kind arising from the use or inability to use the Site or its content,
                      information, links, and materials.
                    </li>
                    <li>
                      Claims arising from errors, omissions, inaccuracies, or destructive properties
                      of any data, information, or material.
                    </li>
                  </ul>
                </li>
                <li>
                  EquityPandit has exercised due care in presenting accurate information and content on
                  the website and will endeavor to ensure its accuracy. However, it does not guarantee or
                  warrant the accuracy, adequacy, correctness, validity, completeness, or suitability for
                  any purpose of the information/content or materials provided on the website.
                  EquityPandit accepts no responsibility concerning the information on the site.
                  Additionally, EquityPandit does not warrant or represent that the information available
                  on this website is entirely error-free and accurate or that the servers making the
                  website available are free of viruses. Despite efforts to provide reliable and
                  error-free information, EquityPandit explicitly disclaims liability arising from any
                  such errors.
                </li>
              </ol>
              <p>&nbsp;</p>
            </ol>
            <ol start="7">
              <li>MODIFICATIONS TO SERVICE &amp; AGREEMENT:</li>
            </ol>
            <p>We&nbsp; reserves the right, at any time and from time to time, to modify or discontinue
              EquityPandit
              services (or any part thereof), temporarily or permanently, with or without prior notice. You
              agree
              that EquityPandit shall not be held liable to you or any third party for any modifications,
              suspensions, or discontinuations of EquityPandit services during the subscription period.</p>
            <p>We reserve the right to modify the terms of this agreement under certain circumstances:</p>
            <ol type="a">
              <li>Responding to changes in Applicable Requirements, new industry guidance or codes of
                practice, or
                decisions or recommendations from entities like courts, ombudsmen, or regulators.
              </li>
              <li>Adjusting to increased costs associated with providing the service, Portfolio, and/or
                Products.
              </li>
              <li>Adapting to technological advancements, changes in our service delivery systems, or
                alterations
                in investment and financial systems.
              </li>
            </ol>
            <p>{`We will only implement changes for these reasons if it's reasonable for us to pass on the impact
                        of
                        those changes to you. We will maintain proportionality in our response to any change.
                        Additionally,
                        we reserve the right to modify the terms of this agreement for any other valid reason not
                        specified
                        above.`}</p>
            <p>Furthermore, we may alter the terms of this agreement where the change would not disadvantage
              you.
              For instance, this change could be to make the agreement fairer for you, enhance its
              comprehensibility, or introduce a new optional product or service.</p>
            <p>Upon making changes to the agreement, we will inform you about the nature of the change, the
              rationale behind it, and the effective date of the modification. Unless Applicable Requirements
              prevent us from doing so or where the change is not to your disadvantage, we will provide you
              with
              reasonable prior written notice of any changes to this agreement. If you are unwilling to accept
              a
              change we make to this agreement, you have the option to terminate this agreement
              immediately.</p>
            <ol start="8">
              <li>Refund &amp; Termination Policy:</li>
            </ol>
            <p>As per SEBI Guidelines:</p>
            <ul>
              <li>In the case of premature termination of the Investment Adviser services according to the
                agreement, a refund of fees for the unexpired period will be provided to the client after
                deducting a breakage fee of 25% of the total fee paid for the services. However, the client
                is
                required to provide a one-month notice period for the premature termination of the services.
              </li>
              <li>If the client requests services for a duration of one (1) month, the fees paid by the client
                for
                the said services shall be non refundable.
              </li>
            </ul>
            <p>&nbsp;</p>
            <p>Illustration for Refunds on Premature Termination:</p>
            <p>Suppose a subscriber has paid Rs. 12,000 for 6 months of services and wishes to terminate the
              agreement after utilizing it for 15 days. Then, the calculation would be as follows:</p>
            <ol type="a">
              <li>Total Fee Paid For 6 months: Rs. 12,000/-</li>
              <li>Monthly Fee = Rs. 2000/-</li>
              <li>Contract Termination Fee: (25% of the Total Fee) = Rs. 3000/-</li>
              <li>Service Used Before Cancellation Request = 0.5 month</li>
              <li>Notice Period = 1 month</li>
              <li>Total Usage Period = (D+E) = 1.5 months</li>
              <li>Unexpired Period = 6 Months – 1.5 Months = 4.5 Months</li>
              <li>Unexpired Services Fee = 4.5 * Rs. 2000 = Rs. 9000/-</li>
              <li>Refund That Will Be Processed = (H-C) = 9000 - 3000 = Rs. 6000/-</li>
            </ol>
            <p>&nbsp;</p>
            <ol start="9">
              <li>No Warranty:</li>
              <ol type="a">
                <li>{`EquityPandit provides the Services and the Software "as is" without any express or
                                implied
                                warranty of any kind, including warranties of merchantability, fitness for a particular
                                purpose,
                                or non-infringement. The Software may contain defects, bugs, inoperable features,
                                errors, or
                                incomplete code. You assume total responsibility and risk for your use of the Service
                                and
                                the
                                Software. EquityPandit does not guarantee that the Service or the Software will be
                                uninterrupted
                                or error-free; it is provided on an "as available" basis. EquityPandit and its
                                affiliates do
                                not
                                warrant the accuracy, completeness, or timeliness of the news and information available
                                through
                                the Service. You acknowledge that any reliance upon any such news, statements, or other
                                information shall be at your sole risk.`}
                </li>
                <li>The accuracy of the information available through the Service is not guaranteed by
                  EquityPandit,
                  and you agree not to rely on any information or other content available through the
                  Service
                  without conducting your own independent investigation and exercising your own business
                  judgments. Neither EquityPandit nor any of its data or content providers shall be liable
                  for
                  any
                  errors or delays in the information or other content available through the Service, or
                  for
                  any
                  action taken in reliance thereon.
                </li>
                <li>No offer on our part with respect to the sale or purchase of any securities is intended
                  or
                  implied, and nothing contained herein is to be construed as a recommendation to buy or
                  sell
                  any
                  securities.
                </li>
                <li>NRI Users must ensure that stocks recommended by us are not on the RBI ban list before
                  investing
                  or executing the order with their trading member.
                </li>
              </ol>
            </ol>
            <ol start="10">
              <li>Indemnification:</li>
              <p>You agree to indemnify, save, and hold EquityPandit, its affiliates, contractors, employees,
                officers, directors, agents, third-party associates, licensors, and partners harmless from
                any and
                all claims, losses, damages, liabilities, costs, and expenses, including legal fees and
                expenses,
                arising from or related to your use or misuse of the services or the Website, any violation
                by you
                of these Terms of Use, or any breach of the representations, warranties, and covenants made
                by you
                herein. EquityPandit reserves the right, at your expense, to assume the exclusive defense
                and
                control of any matter for which you are required to indemnify EquityPandit, including the
                rights to
                settle. You agree to cooperate with EquityPandit in the defense and settlement of these
                claims.
                EquityPandit will make reasonable efforts to notify you of any claim, action, or proceeding
                brought
                by a third party that is subject to the foregoing indemnification upon becoming aware of
                it.</p>
            </ol>
            <ol start="11">
              <li>Limitation of Liability:</li>
            </ol>
            <p>Notwithstanding anything to the contrary contained herein, neither EquityPandit nor its
              affiliated
              companies, subsidiaries, officers, directors, employees, or any related party shall have any
              liability to you or any third party for any indirect, incidental, special, or consequential
              damages
              or any loss of revenue or profits arising under or relating to these Terms of Use. To the
              maximum
              extent permitted by law, you waive, release, discharge, and hold harmless EquityPandit, its
              affiliated and subsidiary companies, and each of their directors, officers, employees, and
              agents,
              from any and all claims, losses, damages, liabilities, expenses, and causes of action arising
              out of
              your use of the Website.</p>
            <ol start="12">
              <li>Severability:</li>
            </ol>
            <p>&nbsp;If any provision of these Terms of Use is found invalid or unenforceable pursuant to a
              judicial
              decree or decision, the remainder of these Terms of Use shall remain valid and enforceable
              according
              to its terms.Top of Form</p>
            <ol start="13">
              <li>Investment Risks:</li>
            </ol>
            <p>You explicitly acknowledge and agree that the information provided on the website does not
              constitute
              advice, and it is not advisable to make any investments or take action solely based on the
              information available on the website. If any investments are made, they are subject to
              third-party
              risks, market risks, and various other forms of risks. Under no circumstances will EquityPandit
              be
              held liable for any losses incurred by you as a result of any investment made in reliance on the
              information available on the website.</p>
            <ol start="14">
              <li>Governing Law:</li>
            </ol>
            <p>These Terms of Use are governed by the Laws of India. Any dispute arising between the Parties
              shall
              be subject to the exclusive jurisdiction of Court(s) in Jaipur.</p>
          </div>
        </div>
      </section>
    </>
  )
  useDocumentTitle('Terms and Conditions');
  if (device === "mobile") {
    return UI;
  } else {
    return (
      <Layout>
        {UI}
      </Layout>
    );
  }
};

export default TermsConditions;
